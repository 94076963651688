import styled from 'styled-components'

export const Container = styled.div`
  > .content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title {
      color: ${(props) => props.theme.colors.gray7};
      margin: 0px;
    }

    .widgets-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .widget-item {
        height: 88px;
        width: 88px;
        background-color: ${(props) => props.theme.colors.gray1};
        display: flex;
        flex-direction: column;
        gap: 6px;
        border: 1px solid transparent;
        border-radius: 6px;
        padding: 20px 16px 0px;
        cursor: pointer;
        transition: 0.3s all;

        &:hover {
          border: 1px solid ${(props) => props.theme.colors.geekblue2};
        }

        &.is-selected {
          border: 1px solid ${(props) => props.theme.colors.geekblue5};
        }

        .widget-icon {
          height: 20px;
          width: 20px;
          background-color: ${(props) => props.theme.colors.geekblue2};
          color: ${(props) => props.theme.colors.geekblue5};
          border-radius: 4px;
          padding: 4px;
        }

        .widget-name {
          color: ${(props) => props.theme.colors.gray10};
          font-weight: 500;
          line-height: 22px;
          margin: 0px;
        }

        .description {
          color: ${(props) => props.theme.colors.gray10};
          font-size: 12px;
          margin: 0px;
        }

        &.is-coming-soon {
          background-color: ${(props) => props.theme.colors.gray3};
          cursor: not-allowed;

          .widget-icon {
            background-color: ${(props) => props.theme.colors.gray4};
            color: ${(props) => props.theme.colors.gray6};
          }

          .widget-name,
          .description {
            color: ${(props) => props.theme.colors.gray6};
          }

          &:hover {
            border: 1px solid transparent;
          }
        }
      }
    }

    .widget-option-box {
      min-height: 60px;
      background-color: ${(props) => props.theme.colors.geekblue1};
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 14px;
      padding: 0px 14px;

      > .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title-container {
          display: flex;
          align-items: center;
          gap: 5px;

          .title {
            color: ${(props) => props.theme.colors.gray13};
            font-size: 12px;
            margin: 0px;
          }

          .icon {
            height: 20px;
            width: 20px;
            color: ${(props) => props.theme.colors.geekblue4};
          }
        }

        .description {
          color: ${(props) => props.theme.colors.gray7};
          font-size: 12px;
          margin: 0px;
        }
      }

      .widget-code-language {
        width: 100%;
      }
    }
  }
`
