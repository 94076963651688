import { useState, useRef } from 'react'
import * as mf from 'mathlive'
import latexParserErrors from '@/constants/latexParserErrors'
import { Container } from './styles'

const MathLiveInput = ({ className, value, ...rest }) => {
  const mfRef = useRef(null)
  const [errors, setErrors] = useState([])

  return (
    <Container className={`${className || ''} math-live-input-container`}>
      <math-field
        ref={mfRef}
        {...rest}
        placeholder={rest?.placeholder || 'f(x) = 2 + x'}
        onInput={(evt) => {
          const errors = mf.validateLatex(evt.target.value)
          setErrors(errors)

          rest?.onChange(evt)
        }}
        disabled={rest?.disabled ? true : null}
      >
        {value}
      </math-field>

      {errors?.map((error) => {
        const errorData = latexParserErrors.find((errorItem) => errorItem.code === error.code)
        return (
          <p key={error.code} className="error">
            {errorData?.message}
            {error.after && (
              <span>
                {' '}
                After <code>{error.after}</code>.
              </span>
            )}
            {error.before && (
              <span>
                {' '}
                Before <code>{error.before}</code>.
              </span>
            )}
          </p>
        )
      })}
    </Container>
  )
}

export default MathLiveInput
