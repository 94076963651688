import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  border-radius: 5px;

  .cm-editor {
    font-size: 13px;
    border-radius: 3px;

    &.cm-focused {
      outline: none !important;
    }

    .cm-content,
    .cm-gutter {
      min-height: 120px;
    }
    .cm-widgetBuffer {
      width: 0px;
    }
    .cm-gutters {
      margin: 1px;
    }
    .cm-line {
      color: #c0caf5;
    }
    .cm-scroller {
      overflow: auto;
    }
  }

  .label {
    height: 0px;
    color: ${(props) => props.theme.colors.gray7};
    font-size: 12px;
    font-weight: 500;
    visibility: hidden;
    opacity: 0;
    margin: 0px;
    transition: all 0.3s;
    user-select: none;
  }

  ${(props) =>
    (props.$hasContent || props.$fixedLabel) &&
    css`
      .label {
        height: 22px;
        visibility: visible;
        opacity: 1;
      }
    `}
`
