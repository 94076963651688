import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import activityTypes from '@/constants/activityTypes'
import AIValidatedActivityForm from './components/AIValidatedActivityForm'
import CodeValidatedActivityForm from './components/CodeValidatedActivityForm'
import InputActivityForm from './components/InputActivityForm'
import LatexValidatedActivityForm from './components/LatexValidatedActivityForm'
import ManuallyValidatedActivityForm from './components/ManuallyValidatedActivityForm'
import MultipleChoiceActivityForm from './components/MultipleChoiceActivityForm'
import { setActivityWizardModalOpen } from '@/store/customModules/actions'
import { Modal } from './styles'

const ActivityWizardModal = () => {
  const dispatch = useDispatch()

  const { currentSubscription } = useSelector((state) => state.subscriptions)
  const { isActivityWizardModalOpen } = useSelector((state) => state.customModules)
  const activity = isActivityWizardModalOpen?.activity

  const canUseAiValidatedActivities = currentSubscription?.available_features?.ai_validated_activities?.available

  const [activityType, setActivityType] = useState(null)

  const renderActivityTypeSelector = () => {
    return (
      <>
        <h3 className="type-selector-title">Select the type of activity you want to add</h3>

        <div className="activity-types-container">
          {activityTypes.map((activity) => {
            const shouldHide = activity.type === 'ai_validated' && !canUseAiValidatedActivities

            if (shouldHide) return null

            return (
              <div
                key={activity.type}
                className={`activity-content ${activity.disabled ? 'is-disabled' : ''} ${activity.highlight ? 'is-highlighted' : ''}`}
                onClick={() => (activity.disabled ? null : setActivityType(activity.type))}
              >
                {activity.icon}
                <h5 className="activity-name">{activity.name}</h5>
                <p className="activity-description">{activity.description}</p>

                {activity.disabled && <p className="activity-description">Coming soon</p>}
              </div>
            )
          })}
        </div>
      </>
    )
  }

  const renderActivityForm = (type) => {
    const activityData = activityTypes.find((activity) => activity.type === type)

    switch (type) {
      case 'input':
        return <InputActivityForm activityTypeName={activityData.name} icon={activityData.icon} />
      case 'multiple_choice':
        return <MultipleChoiceActivityForm activityTypeName={activityData.name} icon={activityData.icon} />
      case 'code_validated':
        return <CodeValidatedActivityForm activityTypeName={activityData.name} icon={activityData.icon} />
      case 'ai_validated':
        return <AIValidatedActivityForm activityTypeName={activityData.name} icon={activityData.icon} />
      case 'manually_validated':
        return <ManuallyValidatedActivityForm activityTypeName={activityData.name} icon={activityData.icon} />
      case 'latex_validated':
        return <LatexValidatedActivityForm activityTypeName={activityData.name} icon={activityData.icon} />
      default:
        return null
    }
  }

  useEffect(() => {
    if (activity) {
      setActivityType(activity?.type)
    }

    return () => setActivityType(null)
  }, [isActivityWizardModalOpen])

  return (
    <Modal
      className="activity-wizard-modal"
      open={!!isActivityWizardModalOpen}
      onCancel={() => dispatch(setActivityWizardModalOpen(false))}
      footer={null}
      destroyOnClose
      $activityType={activityType}
    >
      <div className="content">{activityType ? renderActivityForm(activityType) : renderActivityTypeSelector()}</div>
    </Modal>
  )
}

export default ActivityWizardModal
