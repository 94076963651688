import styled from 'styled-components'

export const Container = styled.div`
  &.version-info-card {
    background-color: ${(props) => props.theme.colors.gray1};
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    padding: 7px 13px;

    .version-item {
      display: flex;
      align-items: center;
      gap: 10px;

      .version-code {
        min-width: 32px;
        min-height: 32px;
        background-color: ${(props) => props.theme.colors.cyan2};
        color: ${(props) => props.theme.colors.cyan6};
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        text-transform: uppercase;
        border-radius: 50%;
        margin: 0px;
      }

      .date {
        margin: 0px;
      }
    }

    .main-button {
      font-weight: 400;
      border-color: ${(props) => props.theme.colors.gray5};
      border-radius: 6px !important;
      padding: 10px;

      .icon {
        color: ${(props) => props.theme.colors.gray6};
        transition: 0.3s all;
      }

      &:hover {
        .icon {
          color: ${(props) => props.theme.colors.geekblue4};
        }
      }
    }
  }
`
