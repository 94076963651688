import MathLiveInput from '@/components/MathLiveInput'
import { Container, Input as SInput } from './styles'

const Input = ({ className, type, label, fixedLabel, ...rest }) => {
  const value = rest?.value || rest?.defaultValue

  return (
    <Container $hasContent={value !== undefined && value !== ''} $fixedLabel={fixedLabel} $isDisabled={rest?.disabled}>
      {label && <p className="label">{label}</p>}

      {type === 'mathlive' ? (
        <MathLiveInput className={`dw-input ${className || ''}`} {...rest} />
      ) : (
        <SInput className={`dw-input ${className || ''}`} {...rest} />
      )}
    </Container>
  )
}

export default Input
