import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { Tag } from 'antd'
import { ArrowCircleUpRight24Regular } from '@fluentui/react-icons'
import Button from '@/components/Button'
import { selectInfoSideDrawerItem } from '@/store/customModules/actions'
import { Container } from './styles'

const VersionCard = () => {
  const dispatch = useDispatch()

  const { currentCustomModuleVersion } = useSelector((state) => state.customModules)

  return (
    <Container className="version-info-card">
      <div className="version-item">
        <p className="version-code">V{currentCustomModuleVersion?.version_number || '1'}</p>

        <Tag className={`status-tag ${currentCustomModuleVersion?.status}`}>
          <span className="indicator" /> {currentCustomModuleVersion?.status}
        </Tag>

        <p className="date">{dayjs(currentCustomModuleVersion?.created).format('lll')}</p>
      </div>

      <Button
        className="main-button"
        type="default"
        onClick={() => dispatch(selectInfoSideDrawerItem('version-history'))}
      >
        View versions <ArrowCircleUpRight24Regular className="icon" />
      </Button>
    </Container>
  )
}

export default VersionCard
