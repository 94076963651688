import { useSelector, useDispatch } from 'react-redux'
import Button from '@/components/Button'
import { toggleUpgradeToProSuccessModal } from '@/store/app/actions'
import ProSuccessBg from '@/assets/images/plans/pro-success.png'
import { Modal } from './styles'

const UpgradeToProModal = () => {
  const dispatch = useDispatch()

  const { isUpgradeToProSuccessModalOpen } = useSelector((state) => state.app)

  return (
    <Modal
      open={isUpgradeToProSuccessModalOpen}
      onCancel={() => dispatch(toggleUpgradeToProSuccessModal(false))}
      keyboard={false}
      footer={null}
      className="upgrade-to-pro-success-modal"
    >
      <div className="content">
        <div className="header-container">
          <img className="image" src={ProSuccessBg} alt="Your are now PRO user" />

          <h1 className="title">
            You are now <span className="pro">PRO</span> user
          </h1>

          <p className="info-text">Your PRO membership is now active!</p>

          <p className="info-text">It might take a few seconds to activate your subscription.</p>

          <p className="info-text">Thank you for subscribing.</p>
        </div>

        <div className="footer">
          <Button
            type="primary"
            size="large"
            ispro="true"
            onClick={() => dispatch(toggleUpgradeToProSuccessModal(false))}
          >
            Keep learning
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default UpgradeToProModal
