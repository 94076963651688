import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Skeleton } from 'antd'
import { BugOutlined, LaptopOutlined } from '@ant-design/icons'
import AIValidatedActivity from './components/AIValidatedActivity'
import CodeActivity from './components/CodeActivity'
import InputActivity from './components/InputActivity'
import LatexValidatedActivity from './components/LatexValidatedActivity'
import ManuallyValidatedActivity from './components/ManuallyValidatedActivity'
import MultipleChoiceActivity from './components/MultipleChoiceActivity'
import AdminIssuesModal from './components/AdminIssuesModal'
import { Container } from './styles'

const Activity = ({ activity, isAssessment, isReview }) => {
  // const [launchId] = useQueryParam('launch_id')

  const [adminIssuesModalIsOpen, setAdminIssuesModalOpen] = useState(false)

  const { userProfile } = useSelector((state) => state.users)
  const { currentModule } = useSelector((state) => state.modules)

  const shouldShowGptTasks = userProfile?.permissions?.includes('content.view_gpt_tasks')
  const shouldShowContentIssues = userProfile?.permissions?.includes('content.view_content_issues')
  const showFooterActions = shouldShowGptTasks || shouldShowContentIssues

  const isActivitySubmitted = !!activity?.user_status?.submitted_answer_at
  const isActivityPassed = activity?.user_status?.is_passed

  const allActivities = currentModule?.module_pages?.map((p) => p?.activities)?.flat()
  let isActivityAllowed = true
  if (currentModule?.config?.resolve_in_order && activity?.order > 0) {
    const prevActivity = allActivities?.find((a) => a?.order === activity?.order - 1)
    isActivityAllowed = prevActivity?.user_status?.is_passed || false
  }

  return (
    <Container
      id="activity-content-container"
      $isReview={isReview}
      $isAssessment={isAssessment}
      className={
        isActivitySubmitted
          ? isActivityPassed === null
            ? 'submitted'
            : isActivityPassed
              ? 'correct'
              : 'incorrect'
          : ''
      }
    >
      <Skeleton active loading={!activity} title={false} paragraph={{ rows: 4 }} />

      {activity?.activity_type === 'code_validated' && (
        <CodeActivity
          activity={activity}
          isReview={isReview}
          isAssessment={isAssessment}
          // launchId={launchId}
          isActivityAllowed={isActivityAllowed}
        />
      )}

      {activity?.activity_type === 'input' && (
        <InputActivity
          activity={activity}
          isReview={isReview}
          isAssessment={isAssessment}
          isActivityAllowed={isActivityAllowed}
        />
      )}

      {activity?.activity_type === 'multiple_choice' && (
        <MultipleChoiceActivity
          activity={activity}
          isReview={isReview}
          isAssessment={isAssessment}
          isActivityAllowed={isActivityAllowed}
        />
      )}

      {activity?.activity_type === 'ai_validated' && (
        <AIValidatedActivity
          activity={activity}
          isReview={isReview}
          isAssessment={isAssessment}
          isActivityAllowed={isActivityAllowed}
        />
      )}

      {activity?.activity_type === 'latex_validated' && (
        <LatexValidatedActivity
          activity={activity}
          isReview={isReview}
          isAssessment={isAssessment}
          isActivityAllowed={isActivityAllowed}
        />
      )}

      {activity?.activity_type === 'manually_validated' && (
        <ManuallyValidatedActivity
          activity={activity}
          isReview={isReview}
          isAssessment={isAssessment}
          isActivityAllowed={isActivityAllowed}
        />
      )}

      {!isAssessment && showFooterActions && (
        <>
          <div className="activity-footer-actions">
            {shouldShowGptTasks && (
              <div className="footer-item" onClick={() => setAdminIssuesModalOpen(true)}>
                <LaptopOutlined />
                <span>GPT</span>
              </div>
            )}

            {shouldShowContentIssues && (
              <div className="footer-item" onClick={() => setAdminIssuesModalOpen(true)}>
                <BugOutlined />
                <span>User Issues</span>
              </div>
            )}
          </div>

          <AdminIssuesModal
            isVisible={adminIssuesModalIsOpen}
            setIsVisible={setAdminIssuesModalOpen}
            activity={activity}
          />
        </>
      )}
    </Container>
  )
}

export default Activity
