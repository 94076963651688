import { isDataWarsHostName } from '@/helpers/env'

export const getDefaultUiConfig = (account) => {
  const loginFormIsEnabled = account?.config?.login?.login_form

  return {
    // general
    showUpgradeToProUi: isDataWarsHostName,
    // navbar
    showNavbarLogo: isDataWarsHostName,
    showNavbarLeftMenu: isDataWarsHostName,
    showNavbarRightMenu: isDataWarsHostName || loginFormIsEnabled,
    showNavbarModuleNavigation: isDataWarsHostName,
    showNavbarStreakInfo: isDataWarsHostName,
    showNavbarGoToTeamsButton: true,
    // module page
    showModuleSkillTrack: isDataWarsHostName,
    showModuleDifficulty: isDataWarsHostName,
    showModuleAuthor: isDataWarsHostName,
    showModuleBoostLab: isDataWarsHostName,
    showModuleProBannerUi: isDataWarsHostName,
    showModuleReportIssue: isDataWarsHostName,
    showModuleShareUi: isDataWarsHostName,
    showModuleRatingUi: isDataWarsHostName,
    showModuleNextUp: isDataWarsHostName,
    showGoToRunningModule: isDataWarsHostName,
    showErrorCardActions: isDataWarsHostName,
  }
}
