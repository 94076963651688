import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Segmented } from 'antd'
import Button from '@/components/Button'
import proFeatures from '@/constants/proFeatures'
import proPrices from '@/constants/proPrices'
import { getPPPInfo, toggleUpgradeToProModal } from '@/store/app/actions'
import { toggleAuthModal, createCheckout } from '@/store/users/actions'
import { Modal } from './styles'

const UpgradeToProModal = () => {
  const dispatch = useDispatch()

  const { pppInfo, isUpgradeToProModalOpen } = useSelector((state) => state.app)
  const { currentSubscription } = useSelector((state) => state.subscriptions)
  const { isAuthenticated, userProfile } = useSelector((state) => state.users)

  const isCustomer = userProfile?.is_customer
  const isPro = currentSubscription?.product?.name !== 'DataWars Basic'
  const isNotificationDismissed = !!localStorage.getItem('dw-dismiss-app-dashboard-top-notification')

  const [billingPeriod, setBillingPeriod] = useState('year')
  const [isLoading, setIsLoading] = useState(false)

  const { message: pppMessage, isVpn, isProxy, isTor } = pppInfo || {}
  const shouldShowPPP = pppMessage && pppMessage !== 'No discounts available' && !isVpn && !isProxy && !isTor

  const handleUserCheckout = async () => {
    setIsLoading(true)
    const checkoutUrl = await dispatch(createCheckout(billingPeriod, window?.location?.pathname))
    if (checkoutUrl) window.location.href = checkoutUrl
    setIsLoading(false)
  }

  useEffect(() => {
    if (
      !isPro &&
      (!isNotificationDismissed || (isNotificationDismissed && isUpgradeToProModalOpen)) &&
      pppInfo === null
    )
      dispatch(getPPPInfo())
  }, [isUpgradeToProModalOpen, pppInfo])

  useEffect(() => {
    if (isUpgradeToProModalOpen && !isAuthenticated) {
      dispatch(toggleUpgradeToProModal(false))
      dispatch(toggleAuthModal(true))
    }
  }, [isUpgradeToProModalOpen, isAuthenticated])

  if (!isAuthenticated) return <></>

  return (
    <Modal
      open={isUpgradeToProModalOpen}
      onCancel={() => {
        dispatch(toggleUpgradeToProModal(false))
      }}
      destroyOnClose
      keyboard={false}
      footer={null}
      className="upgrade-to-pro-modal"
    >
      <div className="content">
        {shouldShowPPP && (
          <div
            className="ppp-block"
            dangerouslySetInnerHTML={{
              __html: pppMessage,
            }}
          />
        )}

        <div className="header-container">
          <p className="pre-title">You are on a Basic plan</p>
          <p className="title">
            Upgrade to PRO{' '}
            <span className="price-container">
              {billingPeriod === 'year' && (
                <>
                  ${proPrices[billingPeriod].month}
                  <span className="small">
                    {proPrices[billingPeriod].monthDecimals ? (
                      <span className="bold">.{proPrices[billingPeriod].monthDecimals}</span>
                    ) : (
                      ''
                    )}
                    <span className="secondary">
                      /month{' '}
                      <span className="gray">
                        | ${proPrices[billingPeriod].year}
                        {proPrices[billingPeriod].yearDecimals ? `.${proPrices[billingPeriod].yearDecimals}` : ''}{' '}
                        yearly
                      </span>
                    </span>
                  </span>
                </>
              )}

              {billingPeriod === 'month' && (
                <>
                  ${proPrices[billingPeriod].month}
                  <span className="small">
                    {proPrices[billingPeriod].monthDecimals ? (
                      <span className="bold">.{proPrices[billingPeriod].monthDecimals}</span>
                    ) : (
                      ''
                    )}
                    <span className="secondary">/month</span>
                  </span>
                </>
              )}
            </span>
          </p>

          <div className="billing-container">
            <Segmented
              className="billing-period-selector"
              size="large"
              value={billingPeriod}
              onChange={setBillingPeriod}
              options={[
                {
                  label: 'Monthly billing',
                  value: 'month',
                },
                {
                  label: (
                    <div className="item">
                      Annual billing <span className="tag">Save 50%</span>
                    </div>
                  ),
                  value: 'year',
                },
              ]}
            />
          </div>
        </div>

        <div className="features-container">
          {proFeatures.map((f, i) => (
            <div key={i} className="feature-box">
              <div className="content">
                <div className="header">
                  <span className="icon">{f.icon}</span>
                  <p className="feature-name">
                    {f.name}
                    {f.comingSoon && <span className="coming-soon-tag">Coming soon</span>}
                  </p>
                </div>

                <p className="feature-description">{f.description}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="footer">
          <Button
            type="primary"
            size="large"
            ispro="true"
            loading={isLoading}
            disabled={isLoading}
            onClick={handleUserCheckout}
          >
            {isCustomer ? 'Upgrade to PRO' : 'Start 7-Day Free Trial'}
          </Button>

          <p className="info-text">You'll be redirected to Stripe checkout</p>
        </div>
      </div>
    </Modal>
  )
}

export default UpgradeToProModal
