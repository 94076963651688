import { useSelector, useDispatch } from 'react-redux'
import { Menu } from 'antd'
import { TextDescription24Regular, Laptop24Regular, ArrowRotateCounterclockwise24Regular } from '@fluentui/react-icons'
import { selectInfoSideDrawerItem } from '@/store/customModules/actions'
import { MenuContainer } from './styles'
import { useEffect } from 'react'

const SiderMenu = ({ setFullScreen }) => {
  const dispatch = useDispatch()

  const { theme } = useSelector((state) => state.app)
  const { infoSideDrawer } = useSelector((state) => state.customModules)

  const items = [
    { label: 'Content', key: 'content', icon: <TextDescription24Regular />, className: 'browse-mode' },
    { label: 'Stack info', key: 'description', icon: <Laptop24Regular /> },
    {
      label: 'Version history',
      key: 'version-history',
      icon: <ArrowRotateCounterclockwise24Regular />,
    },
  ]

  useEffect(() => {
    if (!infoSideDrawer) {
      dispatch(selectInfoSideDrawerItem('content'))
    }
  }, [])

  return (
    <MenuContainer className="sider-menu">
      <Menu
        mode="inline"
        theme={theme === 'dark' ? 'dark' : 'light'}
        items={items}
        selectable={!!infoSideDrawer}
        selectedKeys={[infoSideDrawer]}
        onClick={({ key }) => {
          setFullScreen(false)
          dispatch(selectInfoSideDrawerItem(key))
        }}
      />
    </MenuContainer>
  )
}

export default SiderMenu
