import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import CodeMirror from '@uiw/react-codemirror'
import { loadLanguage } from '@uiw/codemirror-extensions-langs'
import { tokyoNight } from '@uiw/codemirror-theme-tokyo-night'
import codeLanguages from '@/constants/codeLanguages'
import { Container } from './styles'

const CodeEditor = ({ className, value, setValue, label, placeholder, fixedLabel, readOnly, language = 'python' }) => {
  const { theme } = useSelector((state) => state.app)

  // https://github.com/codemirror/codemirror5/tree/master/mode
  const availableLangs = codeLanguages?.map((c) => c.value) || []

  const extensions = useMemo(
    () => (availableLangs.includes(language) ? [loadLanguage(language)] : []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language],
  )

  return (
    <Container
      className={`${className || ''} code-editor`}
      $hasContent={value !== undefined && value !== ''}
      $fixedLabel={fixedLabel}
    >
      {label && <p className="label">{label}</p>}

      <CodeMirror
        value={value}
        onChange={setValue}
        extensions={extensions}
        // basicSetup={{ lineNumbers: false }}
        placeholder={placeholder}
        theme={theme === 'dark' ? undefined : tokyoNight}
        editable={!readOnly}
        readOnly={readOnly}
      />
    </Container>
  )
}

export default CodeEditor
