import { useSelector } from 'react-redux'
import StackCard from '../StackCard'
import StackSizeCard from '../StackSizeCard'
import { Container } from './styles'

const DescriptionDrawerItem = () => {
  const { currentCustomModuleVersion } = useSelector((state) => state.customModules)

  return (
    <Container className="description-drawer-item">
      <div className="content">
        {currentCustomModuleVersion?.stack && <StackCard hasBorder hideActions />}
        {currentCustomModuleVersion?.stack_size && <StackSizeCard hasBorder />}
      </div>
    </Container>
  )
}

export default DescriptionDrawerItem
