import Input from '@/components/Input'
import CodeEditor from '@/components/CodeEditor'
import MarkdownEditor from '@/components/MarkdownEditor'
import { Container } from './styles'

const ActivityWidget = ({
  widgetType,
  widgetMetadata,
  defaultValue,
  value,
  onChange,
  size = 'large',
  label = 'Your answer',
  fixedLabel,
  placeholder = 'Enter your answer',
  disabled = false,
}) => {
  const renderWidget = () => {
    if (!widgetType || (widgetType === 'text' && !widgetMetadata?.rich_text_enabled)) {
      return (
        <Input
          className="activity-input"
          size={size}
          label={label}
          fixedLabel={fixedLabel}
          defaultValue={defaultValue}
          value={value}
          placeholder={placeholder}
          onChange={(evt) => onChange(evt.target.value)}
          disabled={disabled}
        />
      )
    }

    if (widgetType === 'text' && widgetMetadata?.rich_text_enabled) {
      return (
        <>
          <MarkdownEditor
            className="activity-markdown-editor"
            label={label}
            fixedLabel={fixedLabel}
            content={value}
            onChange={onChange}
            placeholder={placeholder}
            readOnly={disabled}
          />
        </>
      )
    }

    if (widgetType === 'code') {
      return (
        <CodeEditor
          className="activity-code-editor"
          label={label}
          fixedLabel={fixedLabel}
          language={widgetMetadata?.code_language}
          value={value}
          setValue={onChange}
          placeholder={placeholder}
          readOnly={disabled}
        />
      )
    }

    if (widgetType === 'latex') {
      return (
        <Input
          className="activity-mathlive-input"
          type={'mathlive'}
          size={size}
          label={label}
          fixedLabel={fixedLabel}
          defaultValue={defaultValue}
          value={value}
          placeholder={placeholder || 'Enter \ your \ answer \ (e.g., \ f(x) = 2 + x)'}
          onChange={(evt) => onChange(evt.target.value)}
          disabled={disabled}
        />
      )
    }
  }

  return <Container className="activity-widget">{renderWidget()}</Container>
}

export default ActivityWidget
