import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { Switch, Collapse, Form } from 'antd'
import { ArrowShuffle24Regular, SlideTextSparkle24Regular } from '@fluentui/react-icons'
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index'
import { reorder } from '@atlaskit/pragmatic-drag-and-drop/reorder'
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { showToast } from '@/utils/toast'
import Button from '@/components/Button'
import Input from '@/components/Input'
import MarkdownEditor from '@/components/MarkdownEditor'
import Answer from './components/Answer'
import {
  addPageContent,
  updatePageContent,
  updateCustomModuleVersion,
  setActivityWizardModalOpen,
} from '@/store/customModules/actions'
import { Container } from '../styles'

const MultipleChoiceActivityForm = ({ activityTypeName, icon }) => {
  const dispatch = useDispatch()

  const { currentCustomModuleVersion, mode, isActivityWizardModalOpen, isCustomModulesLoading } = useSelector(
    (state) => state.customModules,
  )
  const sectionId = isActivityWizardModalOpen?.sectionId
  const activity = isActivityWizardModalOpen?.activity
  const isEditing = !!activity?.id

  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [answers, setAnswers] = useState([])
  const [isShuffleAnswers, setIsShuffleAnswers] = useState(true)
  const [solution, setSolution] = useState('')
  const [hint, setHint] = useState('')
  const [isRichTextAnswer, setIsRichTextAnswer] = useState(false)

  const hasCorrectAnwer = answers.some((a) => a.is_correct)

  const [form] = Form.useForm()

  const addAnswer = () => {
    const answer = {
      id: uuidv4(),
      type: 'multiple_choice_answer',
      content_md: '',
      is_correct: false,
      stick_to_bottom: false,
    }

    setAnswers([...answers, answer])
  }

  const renderAnswers = () => {
    return answers.map((a, index) => (
      <Answer
        key={index}
        answers={answers}
        setAnswers={setAnswers}
        index={index}
        isShuffleAnswers={isShuffleAnswers}
        isRichTextAnswer={isRichTextAnswer}
      />
    ))
  }

  const handleSaveActivity = async () => {
    try {
      await form.validateFields()
    } catch (error) {
      showToast('Please fill all required fields.', 'error')
      return
    }

    const widget = answers.filter((a) => a.is_correct).length === 1 ? 'radio' : 'checkbox'

    const activityBlock = {
      ...(isEditing ? activity : { id: uuidv4() }),
      type: 'multiple_choice',
      title_md: title,
      content_md: content,
      //
      answers: answers,
      widget,
      widget_metadata: {
        rich_text_enabled: isRichTextAnswer,
      },
      shuffle_answers: isShuffleAnswers,
      //
      expected_outcome_md: '',
      solution_md: solution,
      hint_md: hint,
    }

    let updatedContent
    if (isEditing) {
      updatedContent = await dispatch(updatePageContent(sectionId, activityBlock))
      showToast('Activity updated successfully!')
    } else {
      updatedContent = await dispatch(addPageContent(sectionId, activityBlock))
      showToast('Activity created successfully!')
    }

    if (mode === 'edit') {
      dispatch(
        updateCustomModuleVersion(
          currentCustomModuleVersion?.custom_module?.id,
          currentCustomModuleVersion?.id,
          { content_json: updatedContent },
          () => {},
          (err) => showToast(err, 'error'),
        ),
      )
    }

    dispatch(setActivityWizardModalOpen(false))
  }

  const reorderAnswers = useCallback(
    ({ startIndex, finishIndex }) => {
      const updatedAnswers = reorder({
        list: answers,
        startIndex,
        finishIndex,
      })

      setAnswers(updatedAnswers)
    },
    [answers],
  )

  const handleDrop = useCallback(
    ({ source, location }) => {
      const destination = location.current.dropTargets.length
      if (!destination) {
        return
      }

      if (source.data.type === 'ANSWER') {
        const sourceAnswerId = source.data.id
        const sourceAnswerIndex = answers.findIndex((item) => item.id === sourceAnswerId)

        const destinationAnswerRecord = location.current.dropTargets[location.current.dropTargets.length - 1]
        const destinationAnswerId = destinationAnswerRecord.data.id
        const destinationAnswerIndex = answers.findIndex((item) => item.id === destinationAnswerId)

        if (sourceAnswerId === destinationAnswerId) {
          return
        }

        const closestEdgeOfTarget = extractClosestEdge(destinationAnswerRecord.data)

        const finalDestinationAnswerIndex = getReorderDestinationIndex({
          startIndex: sourceAnswerIndex,
          indexOfTarget: destinationAnswerIndex,
          closestEdgeOfTarget,
          axis: 'vertical',
        })

        reorderAnswers({
          startIndex: sourceAnswerIndex,
          finishIndex: finalDestinationAnswerIndex,
        })
      }
    },
    [answers, reorderAnswers],
  )

  useEffect(() => {
    return monitorForElements({
      onDrop: handleDrop,
    })
  }, [handleDrop])

  useEffect(() => {
    if (!activity) return

    setTitle(activity?.title_md)
    setContent(activity?.content_md)
    setAnswers(activity?.answers)
    setIsShuffleAnswers(activity?.shuffle_answers)
    setSolution(activity?.solution_md)
    setHint(activity?.hint_md)
    setIsRichTextAnswer(!!activity?.widget_metadata?.rich_text_enabled)

    form.setFieldsValue({
      Title: activity?.title_md,
    })
  }, [activity])

  return (
    <Container className="multiple-choice-activity-form" $isEditing={isEditing}>
      <div className="header">
        <div className="icon-container">{icon}</div>
        <h4 className="title">
          {isEditing ? 'Editing' : ''} {activityTypeName} activity
        </h4>
      </div>

      <Form className="activity-form" form={form} name="activity-form">
        <div className="activity-form-container">
          <Form.Item name="Title" initialValue={activity?.title_md} rules={[{ required: true }]}>
            <Input
              className="activity-title"
              label="Activity title"
              value={title}
              placeholder="Activity title"
              size="large"
              onChange={(evt) => setTitle(evt.target.value)}
            />
          </Form.Item>

          <MarkdownEditor
            label="Activity description and instructions"
            content={content}
            placeholder="Activity description and instructions"
            onChange={setContent}
          />

          <hr />

          <div className="answers-container">
            <div className="header">
              <p className="title">Add options for the multiple choice question:</p>

              {!!answers?.length && (
                <div className="actions-header">
                  <p className="item">Is correct?</p>
                  <p className="item">Stick to bottom</p>
                </div>
              )}
            </div>

            {renderAnswers()}

            {!(isEditing && mode === 'amend') && (
              <Button className="add-button" type="default" onClick={addAnswer}>
                + add option
              </Button>
            )}
          </div>

          <div className="option-box">
            <div className="content">
              <div>
                <div className="title-container">
                  <ArrowShuffle24Regular className="icon" />
                  <p className="title">Shuffle</p>
                </div>

                <p className="description">Randomizes the order of the options.</p>
              </div>

              <Switch checked={isShuffleAnswers} onChange={setIsShuffleAnswers} />
            </div>
          </div>

          <hr />

          <Collapse
            className="advanced-options-collapse"
            ghost
            expandIconPosition="end"
            items={[
              {
                key: '1',
                label: 'Show advanced options',
                children: (
                  <div className="advanced-options-container">
                    <MarkdownEditor label="Solution" content={solution} placeholder="Solution" onChange={setSolution} />
                    <MarkdownEditor label="Hints" content={hint} placeholder="Hints" onChange={setHint} />

                    <div className="options-container">
                      <div className="option-box">
                        <div className="content">
                          <div>
                            <div className="title-container">
                              <SlideTextSparkle24Regular className="icon" />
                              <p className="title">Rich text</p>
                            </div>

                            <p className="description">Enable rich text for options content.</p>
                          </div>

                          <Switch checked={isRichTextAnswer} onChange={setIsRichTextAnswer} />
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </div>

        <div className="actions">
          <Button type="default" onClick={() => dispatch(setActivityWizardModalOpen(false))}>
            Cancel
          </Button>

          <Button
            type="primary"
            onClick={handleSaveActivity}
            disabled={!answers.length || !hasCorrectAnwer}
            loading={isCustomModulesLoading}
          >
            {isEditing ? 'Update' : 'Create'}
          </Button>
        </div>
      </Form>
    </Container>
  )
}

export default MultipleChoiceActivityForm
