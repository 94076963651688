import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { Switch, Collapse, Form, Radio, Cascader } from 'antd'
import { Add24Regular } from '@fluentui/react-icons'
import codeLanguages from '@/constants/codeLanguages'
import Button from '@/components/Button'
import Input from '@/components/Input'
import Select from '@/components/Select'
import CodeEditor from '@/components/CodeEditor'
import MarkdownEditor from '@/components/MarkdownEditor'
import ActivityWidget from '@/components/ActivityWidget'
import { showToast } from '@/utils/toast'
import {
  addPageContent,
  updatePageContent,
  updateCustomModuleVersion,
  setActivityWizardModalOpen,
} from '@/store/customModules/actions'
import { getCodeSnippets } from '@/store/codeSnippets/actions'
import { Container } from '../styles'

const activityTypeOptions = [
  { label: 'Choose from template', value: 'template' },
  { label: 'Write source code', value: 'source-code' },
]

const codeTemplateOptions = [
  { value: 'python-raw', label: 'Execute in Device' },
  { value: 'jupyter-kernel', label: 'Execute in Notebook' },
]

const CodeValidatedActivityForm = ({ activityTypeName, icon }) => {
  const dispatch = useDispatch()

  const { currentCustomModuleVersion, mode, isActivityWizardModalOpen, isCustomModulesLoading } = useSelector(
    (state) => state.customModules,
  )
  const { currentLab } = useSelector((state) => state.labs)
  const { items: codeSnippetsData } = useSelector((state) => state.codeSnippets)
  const sectionId = isActivityWizardModalOpen?.sectionId
  const activity = isActivityWizardModalOpen?.activity
  const isEditing = !!activity?.id

  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [selectedDevice, setSelectedDevice] = useState(currentLab?.devices?.[0]?.name)
  const [codeTemplate, setCodeTemplate] = useState('jupyter-kernel')
  const [selectedActivityType, setSelectedActivityType] = useState('template')
  const [metadataNotebookPath, setMetadataNotebookPath] = useState('Project.ipynb')
  const [instructorCode, setInstructorCode] = useState('')
  const [selectedCodeSnippet, setSelectedCodeSnippet] = useState()
  const [isCodeInputEnabled, setIsCodeInputEnabled] = useState(false)
  const [codeInputLanguage, setCodeInputLanguage] = useState()
  const [codeInputPlaceholder, setCodeInputPlaceholder] = useState('')
  const [expectedOutcome, setExpectedOutcome] = useState('')
  const [solution, setSolution] = useState('')
  const [hint, setHint] = useState('')

  const [form] = Form.useForm()

  const generateHierarchy = (data) => {
    if (!data?.length) return []

    const hierarchy = []

    const level0 = Array.from(new Set(data.map((item) => item?.category?.lvl0?.[0])))?.filter((item) => item)
    const level1 = Array.from(new Set(data.map((item) => item?.category?.lvl1?.[0])))?.filter((item) => item)
    const level2 = Array.from(new Set(data.map((item) => item?.category?.lvl2?.[0])))?.filter((item) => item)
    const level3 = Array.from(new Set(data.map((item) => item?.category?.lvl3?.[0])))?.filter((item) => item)

    // add levels
    level0.forEach((lvl0, index0) => {
      const hierarchyItem = {
        value: index0,
        label: lvl0,
        children: [],
      }

      level1.forEach((lvl1, index1) => {
        if (lvl1.includes(lvl0)) {
          hierarchyItem?.children?.push({
            value: index1,
            label: lvl1.split(' > ')[1],
            children: [],
          })

          level2.forEach((lvl2, index2) => {
            if (lvl2.includes(lvl1)) {
              hierarchyItem?.children?.[index1]?.children?.push({
                value: index2,
                label: lvl2.split(' > ')[2],
                children: [],
              })

              level3.forEach((lvl3, index3) => {
                if (lvl3.includes(lvl2)) {
                  hierarchyItem?.children?.[index1]?.children?.[index2]?.children?.push({
                    value: index3,
                    label: lvl3.split(' > ')[3],
                    children: [],
                  })
                }
              })
            }
          })
        }
      })

      hierarchy.push(hierarchyItem)
    })

    // add items
    data.forEach((item) => {
      const lastLevel =
        item?.category?.lvl3?.[0] || item?.category?.lvl2?.[0] || item?.category?.lvl1?.[0] || item?.category?.lvl0?.[0]
      const itemHierarchy = lastLevel.split(' > ')

      hierarchy.forEach((lvl0, index0) => {
        if (itemHierarchy[0] !== lvl0?.label) return

        if (!itemHierarchy[1]) {
          hierarchy[index0]?.children?.push({
            value: item?.id,
            label: item?.name,
          })
          return
        }

        hierarchy[index0]?.children?.forEach((lvl1, index1) => {
          if (itemHierarchy[1] !== lvl1?.label) return

          if (!itemHierarchy[2]) {
            hierarchy[index0]?.children[index1]?.children?.push({
              value: item?.id,
              label: item?.name,
            })
            return
          }

          hierarchy[index0]?.children[index1]?.children?.forEach((lvl2, index2) => {
            if (itemHierarchy[2] !== lvl2?.label) return

            if (!itemHierarchy[3]) {
              hierarchy[index0]?.children[index1]?.children[index2]?.children?.push({
                value: item?.id,
                label: item?.name,
              })
              return
            }

            hierarchy[index0]?.children[index1]?.children[index2]?.children?.forEach((lvl3, index3) => {
              if (itemHierarchy[3] !== lvl3?.label) return

              hierarchy[index0]?.children[index1]?.children[index2]?.children[index3]?.children?.push({
                value: item?.id,
                label: item?.name,
              })
            })
          })
        })
      })
    })

    return hierarchy
  }

  const handleGetCodeSnippets = () => {
    dispatch(getCodeSnippets())
  }

  const handleSaveActivity = async () => {
    try {
      await form.validateFields()
    } catch (error) {
      showToast('Please fill all required fields.', 'error')
      return
    }

    const activityBlock = {
      ...(isEditing ? activity : { id: uuidv4() }),
      type: 'code_validated',
      title_md: title,
      content_md: content,
      //
      device_name: selectedDevice,
      ...(selectedActivityType === 'template'
        ? {
            snippet_id: selectedCodeSnippet?.id,
            variables: Object.assign(
              {},
              ...selectedCodeSnippet?.variables?.map((variableData) => ({
                [variableData.name]: variableData.value,
              })),
            ),
            metadata: Object.assign(
              {},
              ...Object.keys(selectedCodeSnippet?.metadata).map((variable) => ({
                [variable]: selectedCodeSnippet?.metadata?.[variable]?.value,
              })),
            ),
          }
        : {
            // Write source code
            code_template: codeTemplate,
            metadata: { notebook_path: metadataNotebookPath },
            instructor_code: instructorCode,
          }),
      //
      expected_outcome_md: expectedOutcome,
      solution_md: solution,
      hint_md: hint,
    }

    if (isCodeInputEnabled) {
      activityBlock.code_input = {
        language: codeInputLanguage || codeLanguages?.[0]?.value,
        placeholder: codeInputPlaceholder,
      }
    } else {
      delete activityBlock.code_input
    }

    if (selectedActivityType === 'source-code') {
      delete activityBlock.snippet_id
      delete activityBlock.variables
    }

    if (selectedActivityType === 'template') {
      delete activityBlock.code_template
      delete activityBlock.instructor_code
    }

    let updatedContent
    if (isEditing) {
      updatedContent = await dispatch(updatePageContent(sectionId, activityBlock))
      showToast('Activity updated successfully!')
    } else {
      updatedContent = await dispatch(addPageContent(sectionId, activityBlock))
      showToast('Activity created successfully!')
    }

    if (mode === 'edit') {
      dispatch(
        updateCustomModuleVersion(
          currentCustomModuleVersion?.custom_module?.id,
          currentCustomModuleVersion?.id,
          { content_json: updatedContent },
          () => {},
          (err) => showToast(err, 'error'),
        ),
      )
    }

    dispatch(setActivityWizardModalOpen(false))
  }

  useEffect(() => {
    if (!selectedCodeSnippet) return

    const updatedVariables = selectedCodeSnippet.variables.map((variableData) => ({
      ...variableData,
      value: variableData.value !== undefined ? variableData.value : variableData.default,
    }))

    const updatedMetadata = selectedCodeSnippet.metadata
      ? Object.assign(
          {},
          ...Object.keys(selectedCodeSnippet.metadata).map((variable) => {
            const variableData = selectedCodeSnippet.metadata[variable]

            return {
              [variable]: {
                ...variableData,
                value: variableData.value !== undefined ? variableData.value : variableData.default,
              },
            }
          }),
        )
      : {}

    setSelectedCodeSnippet({ ...selectedCodeSnippet, variables: updatedVariables, metadata: updatedMetadata })

    if (selectedCodeSnippet?.id !== activity?.snippet_id) {
      if (selectedCodeSnippet?.code_input?.language) {
        setIsCodeInputEnabled(true)
        setCodeInputLanguage(selectedCodeSnippet?.code_input.language)
        setCodeInputPlaceholder(selectedCodeSnippet?.code_input.placeholder || '')
      } else {
        setIsCodeInputEnabled(false)
        setCodeInputLanguage(null)
        setCodeInputPlaceholder('')
      }
    }

    // update form values
    form.setFieldsValue({
      Template: selectedCodeSnippet,
      ...(updatedVariables
        ? Object.assign(
            {},
            ...updatedVariables?.map((variableData) => ({
              [`variable:${variableData.name}`]: variableData.value,
            })),
          )
        : {}),
      ...(updatedMetadata
        ? Object.assign(
            {},
            ...Object.keys(updatedMetadata).map((variable) => ({
              [`metadata:${variable}`]: updatedMetadata[variable].value,
            })),
          )
        : {}),
    })
  }, [selectedCodeSnippet?.name])

  useEffect(() => {
    if (!activity) return

    setSelectedActivityType(activity?.snippet_id ? 'template' : 'source-code')

    setTitle(activity?.title_md)
    setContent(activity?.content_md)
    setSelectedDevice(activity?.device_name)

    if (activity?.snippet_id) {
      const auxCodeSnippet = codeSnippetsData?.find((snippet) => snippet.id === activity?.snippet_id)

      const variables = auxCodeSnippet?.variables?.map((variableData) => ({
        ...variableData,
        value: activity?.variables[variableData.name],
      }))

      const metadata = auxCodeSnippet?.metadata
        ? Object.assign(
            {},
            ...Object.keys(auxCodeSnippet.metadata).map((variable) => {
              const variableData = auxCodeSnippet.metadata[variable]

              return {
                [variable]: {
                  ...variableData,
                  value: activity?.metadata[variable],
                },
              }
            }),
          )
        : {}

      setSelectedCodeSnippet({ ...auxCodeSnippet, variables, metadata })
    } else {
      setCodeTemplate(activity?.code_template)
      setMetadataNotebookPath(activity?.metadata?.notebook_path)
      setInstructorCode(activity?.instructor_code)

      // update form values
      form.setFieldsValue({
        code_template: activity?.code_template,
        notebook_path: activity?.metadata?.notebook_path,
        instructor_code: activity?.instructor_code,
      })
    }

    if (activity?.code_input) {
      setIsCodeInputEnabled(true)
      setCodeInputLanguage(activity?.code_input.language)
      setCodeInputPlaceholder(activity?.code_input.placeholder)
    } else {
      setIsCodeInputEnabled(false)
      setCodeInputLanguage(null)
      setCodeInputPlaceholder('')
    }

    setExpectedOutcome(activity?.expected_outcome_md)
    setSolution(activity?.solution_md)
    setHint(activity?.hint_md)

    // update form values
    form.setFieldsValue({
      Title: activity?.title_md,
    })
  }, [activity, codeSnippetsData])

  useEffect(() => {
    handleGetCodeSnippets()
  }, [])

  return (
    <Container className="code-validated-activity-form">
      <div className="header">
        <div className="icon-container">{icon}</div>
        <h4 className="title">
          {isEditing ? 'Editing' : ''} {activityTypeName} activity
        </h4>
      </div>

      <Form className="activity-form" form={form} name="activity-form">
        <div className="activity-form-container">
          <Form.Item name="Title" initialValue={activity?.title_md} rules={[{ required: true }]}>
            <Input
              className="activity-title"
              label="Activity title"
              value={title}
              placeholder="Activity title"
              size="large"
              onChange={(evt) => setTitle(evt.target.value)}
            />
          </Form.Item>

          <MarkdownEditor
            label="Activity description and instructions"
            content={content}
            placeholder="Activity description and instructions"
            onChange={setContent}
          />
          <hr />

          {currentLab?.devices?.length >= 2 && (
            <>
              <p className="text">Choose device:</p>
              <Select
                value={selectedDevice}
                onChange={setSelectedDevice}
                options={currentLab?.devices?.map((device) => ({
                  value: device.name,
                  label: device.name,
                }))}
                disabled={isEditing && mode === 'amend'}
              />
            </>
          )}

          <p className="text bold">Validation to perform:</p>
          <Radio.Group
            className="activity-type-selector"
            options={activityTypeOptions}
            optionType="button"
            value={selectedActivityType}
            onChange={(evt) => setSelectedActivityType(evt.target.value)}
            size="large"
            block
            name="activity-type"
            disabled={isEditing && mode === 'amend'}
          />

          {selectedActivityType === 'template' && (
            <>
              <Form.Item name="Template" rules={[{ required: true }]}>
                <Cascader
                  className="code-snippet-cascader"
                  name="code-snippet-cascader"
                  options={generateHierarchy(codeSnippetsData)}
                  value={selectedCodeSnippet}
                  onChange={(value) => {
                    if (!value) setSelectedCodeSnippet(null)

                    const codeSnippet = codeSnippetsData?.find((item) => item.id === value[value?.length - 1])
                    setSelectedCodeSnippet(codeSnippet)
                  }}
                  label="Predefined template"
                  placeholder="Select a predefined template"
                  displayRender={() => selectedCodeSnippet?.name}
                  showSearch={{
                    filter: (inputValue, path) =>
                      path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1),
                  }}
                />
              </Form.Item>

              {selectedCodeSnippet?.description && (
                <p className="text code-snippet-description">{selectedCodeSnippet?.description}</p>
              )}

              {!!selectedCodeSnippet?.variables?.length && <p className="text bold">Variables:</p>}
              {selectedCodeSnippet?.variables?.map((variableData, index) => (
                <Form.Item
                  key={variableData?.name}
                  name={`variable:${variableData?.name}`}
                  rules={[{ required: variableData?.required, message: 'This field is required.' }]}
                >
                  <ActivityWidget
                    widgetType={variableData?.widget_type}
                    widgetMetadata={variableData?.widget_metadata}
                    size="default"
                    label={variableData?.description}
                    fixedLabel
                    placeholder={`${variableData?.name}${variableData?.default !== undefined ? `. Default: "${variableData?.default}"` : ''}`}
                    defaultValue={variableData?.default}
                    value={variableData?.value}
                    onChange={(value) => {
                      const updatedVariables = selectedCodeSnippet.variables.map((v) =>
                        v.name === variableData?.name ? { ...v, value } : v,
                      )

                      setSelectedCodeSnippet({ ...selectedCodeSnippet, variables: updatedVariables })
                    }}
                  />
                </Form.Item>
              ))}

              {selectedCodeSnippet?.metadata && !!Object.keys(selectedCodeSnippet?.metadata)?.length && (
                <p className="text bold">Metadata:</p>
              )}
              {selectedCodeSnippet?.metadata &&
                Object.keys(selectedCodeSnippet?.metadata)?.map((variable, index) => {
                  const variableData = selectedCodeSnippet.metadata[variable]

                  return (
                    <Form.Item
                      key={variable}
                      name={`metadata:${variable}`}
                      rules={[{ required: variableData?.required, message: 'This field is required.' }]}
                    >
                      <Input
                        label={variable}
                        fixedLabel
                        placeholder={`${variable}${variableData?.default !== undefined ? `. Default: ${variableData?.default}` : ''}`}
                        value={variableData?.value}
                        defaultValue={variableData?.default}
                        onChange={(evt) => {
                          const updatedMetadata = {
                            ...selectedCodeSnippet.metadata,
                            [variable]: {
                              ...variableData,
                              value: evt.target.value,
                            },
                          }
                          setSelectedCodeSnippet({ ...selectedCodeSnippet, metadata: updatedMetadata })
                        }}
                        disabled={isEditing && mode === 'amend'}
                      />
                    </Form.Item>
                  )
                })}
            </>
          )}

          {selectedActivityType === 'source-code' && (
            <>
              <Select
                className="code-template-select"
                placeholder="Where to execute?"
                value={codeTemplate}
                onChange={setCodeTemplate}
                options={codeTemplateOptions}
                disabled={isEditing && mode === 'amend'}
              />

              {codeTemplate === 'jupyter-kernel' && (
                <Form.Item
                  name="notebook_path"
                  initialValue="Project.ipynb"
                  value={metadataNotebookPath}
                  rules={[{ required: true }]}
                >
                  <Input
                    label="Notebook path"
                    placeholder="Notebook path. Default: Project.ipynb"
                    onChange={(evt) => setMetadataNotebookPath(evt.target.value)}
                    disabled={isEditing && mode === 'amend'}
                  />
                </Form.Item>
              )}

              <Form.Item
                name="instructor_code"
                value={instructorCode}
                rules={[
                  {
                    validator: () => {
                      if (!instructorCode) {
                        return Promise.reject('Instructor code is required.')
                      }
                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <CodeEditor
                  language={'python'}
                  value={instructorCode}
                  setValue={setInstructorCode}
                  label="Instructor/validation code"
                  placeholder={'Write your instructor/validation code here...'}
                />
              </Form.Item>
            </>
          )}

          {((selectedActivityType === 'template' && selectedCodeSnippet) || selectedActivityType === 'source-code') && (
            <div className="option-box">
              <div className="content">
                <div className="title-container">
                  <Add24Regular className="icon" />
                  <p className="title">Requires student's input</p>
                </div>

                <Switch
                  value={isCodeInputEnabled}
                  onChange={setIsCodeInputEnabled}
                  disabled={selectedActivityType === 'template'}
                />
              </div>

              {isCodeInputEnabled && (
                <div className="extra-content">
                  <Select
                    className="user-input-language-select"
                    label="Code language"
                    placeholder="Select code language"
                    value={codeInputLanguage}
                    defaultValue="python"
                    onChange={selectedActivityType === 'template' ? null : setCodeInputLanguage}
                    options={codeLanguages}
                    disabled={selectedActivityType === 'template'}
                  />

                  <div>
                    <CodeEditor
                      language={codeInputLanguage}
                      value={codeInputPlaceholder}
                      setValue={setCodeInputPlaceholder}
                      label="Placeholder code"
                      placeholder="Write placeholder code here..."
                    />

                    {selectedActivityType === 'source-code' && (
                      <p className="text small">
                        Your student's input will be available in the variable <code>STUDENT_ANSWER</code>.
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          <hr />

          <Collapse
            className="advanced-options-collapse"
            ghost
            expandIconPosition="end"
            items={[
              {
                key: '1',
                label: 'Show advanced options',
                children: (
                  <div className="advanced-options-container">
                    <MarkdownEditor
                      label="Expected outcome"
                      content={expectedOutcome}
                      placeholder="Expected outcome"
                      onChange={setExpectedOutcome}
                    />
                    <MarkdownEditor label="Solution" content={solution} placeholder="Solution" onChange={setSolution} />
                    <MarkdownEditor label="Hints" content={hint} placeholder="Hints" onChange={setHint} />
                  </div>
                ),
              },
            ]}
          />
        </div>

        <div className="actions">
          <Button type="default" onClick={() => dispatch(setActivityWizardModalOpen(false))}>
            Cancel
          </Button>

          <Button type="primary" htmlType="submit" onClick={handleSaveActivity} loading={isCustomModulesLoading}>
            {isEditing ? 'Update' : 'Create'}
          </Button>
        </div>
      </Form>
    </Container>
  )
}

export default CodeValidatedActivityForm
