import { useEffect } from 'react'

const usePreventTabClose = (enabled) => {
  useEffect(() => {
    if (!enabled) return

    const handleBeforeUnload = (event) => {
      event.preventDefault()
      event.returnValue = 'Changes you made may not be saved.'
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [enabled])
}

export default usePreventTabClose
