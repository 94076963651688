import API from '@/services/client'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const getCustomModule = async (customModuleId) => {
  const route = `custom-modules/${customModuleId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

// const getCustomModuleVersions = async (customModuleId) => {
//   const route = `custom-modules/${customModuleId}/versions`
//   return API.get(route)
//     .then((response) => Promise.resolve(response.data))
//     .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
// }

const getCustomModuleVersion = async (customModuleId, versionId) => {
  const route = `custom-modules/${customModuleId}/versions/${versionId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const patchCustomModuleVersion = async (customModuleId, versionId, data) => {
  const route = `custom-modules/${customModuleId}/versions/${versionId}`
  return API.patch(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postCustomModuleVersion = async (customModuleId) => {
  const route = `custom-modules/${customModuleId}/versions`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postCustomModuleVersionEdit = async (customModuleId, versionId) => {
  const route = `custom-modules/${customModuleId}/versions/${versionId}/edit`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postCustomModuleVersionStop = async (customModuleId, versionId, data) => {
  const route = `custom-modules/${customModuleId}/versions/${versionId}/stop`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postCustomModuleVersionPublish = async (customModuleId, versionId) => {
  const route = `custom-modules/${customModuleId}/versions/${versionId}/publish`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

export {
  getCustomModule,
  getCustomModuleVersion,
  patchCustomModuleVersion,
  postCustomModuleVersion,
  postCustomModuleVersionEdit,
  postCustomModuleVersionStop,
  postCustomModuleVersionPublish,
}
