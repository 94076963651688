import styled, { css } from 'styled-components'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.gray1};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 14px;
  border-radius: 10px;
  padding: 20px 30px;

  ${(props) =>
    props.$hasBorder &&
    css`
      border: 1px solid ${(props) => props.theme.colors.gray4};
    `}

  > .header {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .image-container {
      height: 26px;
      width: 36px;
      background-color: ${(props) => props.theme.colors.geekblue1};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px;
      margin-top: 6px;

      > svg {
        height: 20px;
        width: 20px;
        color: ${(props) => props.theme.colors.geekblue5};
      }
    }

    .name {
      margin: 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .value-boxes-container {
    display: flex;
    flex-direction: column;
    gap: 9px;

    .value-box {
      background-color: ${(props) => props.theme.colors.gray3};
      position: relative;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      border-radius: 4px;
      padding: 4px 6px;

      .key {
        color: ${(props) => props.theme.colors.gray7};
        display: flex;
        gap: 5px;
        align-items: center;
        font-size: 12px;
        line-height: 20px;
        margin: 0px;

        .icon {
          height: 20px;
          width: 20px;
          color: ${(props) => props.theme.colors.gray7};
        }
      }

      .value {
        font-size: 12px;
        font-weight: 700;
        line-height: 20px;
        margin: 0px;
      }

      .progress-container {
        line-height: 0px;
        position: absolute;
        left: 0px;
        bottom: 0px;

        .ant-progress-inner {
          .ant-progress-bg {
            background: transparent;
          }
        }
      }

      &.low {
        .progress-container {
          .ant-progress-inner {
            .ant-progress-bg {
              background: ${(props) => props.theme.colors.blue4};
            }
          }
        }
      }

      &.medium {
        background-color: ${(props) => props.theme.colors.orange1};

        .progress-container {
          .ant-progress-inner {
            .ant-progress-bg {
              background: ${(props) => props.theme.colors.orange4};
            }
          }
        }
      }

      &.high {
        background-color: ${(props) => props.theme.colors.red1};

        .progress-container {
          .ant-progress-inner {
            .ant-progress-bg {
              background: ${(props) => props.theme.colors.red4};
            }
          }
        }
      }
    }
  }
`
