import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Spin } from 'antd'
import { Edit24Regular, DeveloperBoard24Regular, Wifi124Filled, Collections24Regular } from '@fluentui/react-icons'
import { LoadingOutlined, RocketOutlined } from '@ant-design/icons'
import getIconByUrl from '@/utils/getIconByUrl'
import { capitalize } from '@/utils/functions'
import InlineEdit from '@/components/InlineEdit'
import JumpboxCard from '@/pages/PlaygroundsPage/components/JumpboxCard'
import DataSourceCard from '@/pages/PlaygroundsPage/components/DataSourceCard'
import SettingBox from '@/components/SettingBox'
import UploadPlaygroundImageButton from '../UploadPlaygroundImageButton'
import { updateWizard } from '@/store/playgrounds/actions'
import NoDataSource from '@/assets/images/playgrounds/datasources/no-data-source.svg'
import { Container } from './styles'

const DataSourceSettings = ({ isCustomProject }) => {
  const dispatch = useDispatch()

  const { currentSubscription } = useSelector((state) => state.subscriptions)
  const { wizard, isLoading } = useSelector((state) => state.playgrounds)

  const canBoostLab = currentSubscription?.available_features?.boost_lab?.available
  const canUseInternetResolution = currentSubscription?.available_features?.internet_resolution?.available

  const defaultName = isCustomProject
    ? 'Add a name to your project'
    : `${wizard?.dataSource?.name || wizard?.jumpbox?.name} playground`

  const [mainIcon, setMainIcon] = useState(null)

  useEffect(() => {
    if (!wizard) return

    getIconByUrl({
      iconUrl: wizard?.dataSource?.theme?.logo_url || 'ant-BuildOutlined',
      className: 'main-icon',
      onReady: setMainIcon,
    })
  }, [wizard])

  return (
    <Container className="datasource-settings" $playgroundBackgroundImage={wizard?.imageUrl}>
      <div className="content">
        <div className="header">
          <div className="image-container">
            {mainIcon}

            <UploadPlaygroundImageButton />
          </div>

          <div className="info">
            <div className="title-container">
              {isLoading ? <Spin indicator={<LoadingOutlined spin />} /> : <Edit24Regular className="icon" />}

              {isLoading ? (
                <div className="title-row">
                  <h4 className="title">{capitalize(wizard?.name) || defaultName}</h4>
                </div>
              ) : (
                <InlineEdit
                  className="title-row"
                  onSave={(value) => dispatch(updateWizard({ name: capitalize(value) }))}
                  value={wizard?.name}
                  placeholder={defaultName}
                  renderAs={'h4'}
                />
              )}
            </div>

            <div className="description-container">
              {isLoading ? (
                <div className="description-row">
                  <p className="description">{wizard?.description || 'Add a short description here'}</p>
                </div>
              ) : (
                <InlineEdit
                  className="description-row"
                  type={'textarea'}
                  onSave={(value) => dispatch(updateWizard({ description: value }))}
                  value={wizard?.description?.length ? wizard?.description?.trim() : ''}
                  placeholder="Add a short description here"
                  renderAs={'p'}
                />
              )}
            </div>
          </div>
        </div>

        <div className="configuration">
          <div className="playground-overview">
            <div className="overview-section">
              <p className="section-title">Selected environment</p>
              <JumpboxCard jumpbox={wizard?.jumpbox} />
            </div>

            <div className="overview-section">
              <p className="section-title">Selected dataset</p>
              {wizard?.dataSource?.id ? (
                <DataSourceCard dataSource={wizard?.dataSource} />
              ) : (
                <div className="no-data-source-content">
                  <img className="image" src={NoDataSource} alt="No dataset selected" />

                  <div className="title-container">
                    <h5 className="title">No dataset selected</h5>
                    <p className="description">
                      It'll be just the compute environment. This can't be changed after creation.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="playground-settings">
            <p className="section-title">Settings</p>

            <div className="settings-container">
              <SettingBox
                title={isCustomProject ? 'Boost Project!' : 'Boost Playground!'}
                subtitle={`Extra RAM and CPU for your ${isCustomProject ? 'project' : 'playground'}`}
                icon={<RocketOutlined />}
                value={wizard?.boost}
                onToggle={(value) => dispatch(updateWizard({ boost: value }))}
                showProTooltip={!canBoostLab}
                proTitle={`Become PRO and boost ${isCustomProject ? 'project' : 'playground'}.`}
                proContent={
                  <p className="text">
                    Boosted ${isCustomProject ? 'project' : 'playground'} have extra CPU and RAM. You'll notice the
                    difference while doing compute-intensive activities or processing a large datasets.
                  </p>
                }
                proContentIsLarge
                isLoading={isLoading}
              />

              <SettingBox
                title="Internet"
                subtitle="Create an environment with access to the web"
                icon={<Wifi124Filled />}
                value={wizard?.internetOn}
                onToggle={(value) => dispatch(updateWizard({ internetOn: value }))}
                isComingSoon={!canUseInternetResolution}
                isLoading={isLoading}
              />

              <SettingBox
                title="GPU"
                subtitle="Create an environment with GPU available for Deep Learning"
                icon={<DeveloperBoard24Regular />}
                isComingSoon
              />

              <SettingBox
                title="Install Libraries"
                subtitle="Install any custom libraries that you want to use"
                icon={<Collections24Regular />}
                isComingSoon
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default DataSourceSettings
