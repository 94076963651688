import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import invariant from 'tiny-invariant'
import { Switch, Tooltip } from 'antd'
import { DeleteRegular } from '@fluentui/react-icons'
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import { attachClosestEdge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { DragHandleButton } from '@atlaskit/pragmatic-drag-and-drop-react-accessibility/drag-handle-button'
import Input from '@/components/Input'
import MarkdownEditor from '@/components/MarkdownEditor'
import DropIndicator from '../../../../DropIndicator'

const Answer = ({ answers, setAnswers, index, isShuffleAnswers, isRichTextAnswer }) => {
  const answerRef = useRef(null)

  const { mode, isActivityWizardModalOpen } = useSelector((state) => state.customModules)

  const activity = isActivityWizardModalOpen?.activity
  const isEditing = !!activity?.id

  const answer = answers[index]

  const [isDragging, setIsDragging] = useState(false)
  const [closestEdge, setClosestEdge] = useState(null)

  const updateAnswer = (index, data = {}, isExclusive) => {
    let newAnswers = [...answers]

    if (isExclusive) {
      newAnswers = newAnswers?.map((a) => ({
        ...a,
        ...Object.assign({}, ...Object.keys(data)?.map((key) => ({ [key]: false }))),
      }))
    }

    newAnswers = newAnswers.map((a, i) => (i === index ? { ...a, ...data } : a))

    setAnswers(newAnswers)
  }

  const removeAnswer = (index) => {
    const newAnswers = answers.filter((_, i) => i !== index)
    setAnswers(newAnswers)
  }

  useEffect(() => {
    const answerEl = answerRef.current
    invariant(answerEl)

    return combine(
      draggable({
        element: answerEl,
        getInitialData: () => ({ type: 'ANSWER', id: answer.id }),
        canDrag: () => !isShuffleAnswers,
        onDragStart: () => setIsDragging(true),
        onDrop: () => setIsDragging(false),
      }),
      dropTargetForElements({
        element: answerEl,
        canDrop: ({ source, self }) => !isShuffleAnswers,
        getData: ({ input, element }) => {
          const data = { type: 'ANSWER', id: answer.id }

          return attachClosestEdge(data, {
            input,
            element,
            allowedEdges: ['top', 'bottom'],
          })
        },
        getIsSticky: () => true,
        onDragEnter: (args) => {
          if (args.source.data.type === 'ANSWER' && args.source.data.id !== answer.id) {
            setClosestEdge(extractClosestEdge(args.self.data))
          }
        },
        onDrag: (args) => {
          if (args.source.data.type === 'ANSWER' && args.source.data.id !== answer.id) {
            setClosestEdge(extractClosestEdge(args.self.data))
          }
        },
        onDragLeave: () => {
          setClosestEdge(null)
        },
        onDrop: () => {
          setClosestEdge(null)
        },
      }),
    )
  }, [answer.id, isShuffleAnswers])

  return (
    <div ref={answerRef} className={`answer-box ${isDragging ? 'is-dragging' : ''}`}>
      {!isShuffleAnswers && (
        <div className="drag-icon-container">
          <DragHandleButton className="drag-button" label="Reorder" />
        </div>
      )}

      <div className="answer-container">
        {isRichTextAnswer ? (
          <MarkdownEditor
            content={answer?.content_md}
            placeholder="Option content"
            onChange={(md) => updateAnswer(index, { content_md: md })}
          />
        ) : (
          <Input
            value={answer?.content_md}
            placeholder="Option content"
            onChange={(evt) => updateAnswer(index, { content_md: evt.target.value })}
          />
        )}
      </div>

      <div className="actions">
        <Switch
          checked={answer.is_correct}
          onChange={(checked) => updateAnswer(index, { is_correct: checked })}
          disabled={isEditing && mode === 'amend'}
        />

        <Switch
          checked={answer.stick_to_bottom}
          onChange={(checked) => {
            updateAnswer(index, { stick_to_bottom: checked }, true)
          }}
          disabled={isEditing && mode === 'amend'}
        />

        {!(isEditing && mode === 'amend') && (
          <Tooltip title="Remove answer">
            <div className="icon-container" onClick={() => removeAnswer(index)}>
              <DeleteRegular className="icon" />
            </div>
          </Tooltip>
        )}
      </div>

      {closestEdge && <DropIndicator edge={closestEdge} />}
    </div>
  )
}

export default Answer
