import styled from 'styled-components'

export const MainContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray3};
  display: flex;
  flex-direction: column;
  overflow: auto;

  .error-card-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .loading-container {
    width: 100%;
    padding: 16px 32px;
  }

  .empty-state-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 40px 0px 10px;

    > .content {
      max-width: 320px;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 6px;

      .image {
        margin: 20px 0px;
      }

      .title {
        margin: 0px;
      }

      .text {
        color: ${(props) => props.theme.colors.gray8};
        text-align: center;
        margin: 0px;
      }
    }
  }

  > .actions {
    width: 100%;
    background-color: ${(props) => (props.$isEmptyState ? 'transparent' : props.theme.colors.gray1)};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 24px 12px;

    .add-button {
      height: auto;
      max-width: 330px;
      width: ${(props) => (props.$isEmptyState ? '60%' : '100%')};
      border-radius: 6px !important;
      padding: 12px 16px;

      .icon {
        height: 20px;
        width: 20px;
      }

      &:disabled {
        background-color: transparent;
      }
    }
  }
`

export const SecondaryContainer = styled.div`
  height: 100%;
  background-color: ${(props) => props.theme.colors.gray4};
  position: relative;
  display: flex;
  align-items: center;

  > .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .fade-in-mask {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 18px;
      overflow: auto;
      padding: 20px 60px;
    }

    .row {
      display: flex;
      gap: 20px;
    }

    .stack-card,
    .stack-size-card {
      height: 230px;
      width: 50%;
    }

    .pre-loading-card ul {
      margin: 0px;
      li {
        height: 46px;
        width: 100% !important;
        border-radius: 10px;
      }
    }

    .main-loading-card ul {
      margin: 0px;
      li {
        height: 300px;
        width: 100% !important;
        border-radius: 10px;
      }
    }

    .secondary-loading-card ul {
      margin: 0px;

      li {
        height: 230px;
        width: 100% !important;
        border-radius: 10px;
      }
    }
  }
`
