import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { Tooltip, Modal, Tag, Button as AntdButton } from 'antd'
import { CodeOutlined, BulbOutlined, EyeOutlined, BugOutlined, WarningFilled } from '@ant-design/icons'
import Button from '@/components/Button'
import ActivityWidget from '@/components/ActivityWidget'
import Replacement from '@/pages/ModulePage/components/Replacement'
import ActivityReactions from '@/pages/ModulePage/components/Activity/components/ActivityReactions'
import { toggleAuthModal } from '@/store/users/actions'
import { skipAssessmentContent } from '@/store/modules/actions'
import {
  createActivityAttempt,
  createActivityHint,
  createActivitySolution,
  toggleReportIssueModal,
} from '@/store/activities/actions'

const ManuallyValidatedActivity = ({ activity, isAssessment, isReview, isActivityAllowed }) => {
  const dispatch = useDispatch()

  const { uiConfig } = useSelector((state) => state.accounts)
  const { isAuthenticated } = useSelector((state) => state.users)
  const { currentSubscription } = useSelector((state) => state.subscriptions)
  const { isLoading: isActivityLoading } = useSelector((state) => state.activities)
  const { currentModule } = useSelector((state) => state.modules)
  const { currentLab: lab, currentViewIsLoaded } = useSelector((state) => state.labs)
  const hasActiveLabSession = !!lab?.allocated_session

  const [answer, setAnswer] = useState('')
  const [infoBlock, setInfoBlock] = useState()

  const canViewEarlyAccess =
    currentSubscription?.available_features?.early_access_content?.available || !currentModule?.early_access
  const canRunProContent = currentSubscription?.available_features?.pro_content?.available || !currentModule?.is_pro
  const canExecute = canViewEarlyAccess && canRunProContent

  const shouldViewActivitySolutions = currentModule?.user_status?.last_module_attempt?.config?.show_solutions

  const moduleAttempt = currentModule?.user_status?.last_module_attempt
  const isAttemptActive = moduleAttempt?.is_active

  const isActivitySubmitted = !!activity.user_status?.submitted_answer_at
  const answerSubmittedAt = activity.user_status?.submitted_answer_at

  const isActivityPassed = activity.user_status?.is_passed
  const isActivityReCheckPassed = activity?.user_status?.grading
    ? activity?.user_status?.grading?.is_passed
    : activity.user_status?.is_passed
  const activityStdout = activity?.user_status?.grading
    ? activity?.user_status?.grading?.grade_output?.stdout
    : activity.user_status?.submitted_answer?.stdout
  const activityStderr = activity?.user_status?.grading
    ? activity?.user_status?.grading?.grade_output?.stderr
    : activity.user_status?.submitted_answer?.stderr

  const solutionRequested = activity.user_status?.solution_requested
  const solutionRequestedAt = solutionRequested && activity.user_status?.solution_requested_at
  const isSolutionRequestedBeforeSubmission =
    solutionRequested && dayjs(solutionRequestedAt).isBefore(dayjs(answerSubmittedAt))

  const hintRequested = activity.user_status?.hint_requested
  const hintRequestedAt = hintRequested && activity.user_status?.hint_requested_at
  const isHintRequestedBeforeSubmission = hintRequested && dayjs(hintRequestedAt).isBefore(dayjs(answerSubmittedAt))

  const handleSubmitActivity = () => {
    if (!isAuthenticated) {
      dispatch(toggleAuthModal(true))
      return
    }

    const data = {
      activityId: activity.id,
      textAnswer: answer,
    }

    dispatch(createActivityAttempt(data))
  }

  const handleRequestHint = () => {
    if (activity?.hint_html) {
      setInfoBlock(infoBlock === 'hint' ? null : 'hint')
      return
    }

    const data = {
      activityId: activity.id,
    }
    dispatch(createActivityHint(data))
    setInfoBlock('hint')
  }

  const handleRequestSolution = () => {
    if (!shouldViewActivitySolutions) return

    if (activity?.solution_html) {
      setInfoBlock(infoBlock === 'solution' ? null : 'solution')
      return
    }

    const data = {
      activityId: activity.id,
    }
    dispatch(createActivitySolution(data))
    setInfoBlock('solution')
  }

  const activityActions = (
    <div className="activity-actions">
      {activity?.has_hint && (!isReview || (isReview && activity?.hint_html && isHintRequestedBeforeSubmission)) && (
        <Tooltip title={isAttemptActive ? 'View hint' : 'Start project to reveal hint'}>
          <AntdButton
            className={`hint-btn ${infoBlock === 'hint' && 'is-selected'}`}
            shape="circle"
            icon={<BulbOutlined />}
            onClick={handleRequestHint}
            loading={isActivityLoading && isActivityLoading === activity?.id}
            disabled={!isAttemptActive || isActivityLoading || !canExecute}
          />
        </Tooltip>
      )}

      {activity?.has_solution &&
        shouldViewActivitySolutions &&
        !isAssessment &&
        (!isReview || (isReview && activity?.solution_html && isSolutionRequestedBeforeSubmission)) && (
          <Tooltip title={isAttemptActive ? 'View solution' : 'Start project to reveal solution'}>
            <AntdButton
              className={`solution-btn ${infoBlock === 'solution' && 'is-selected'} ${
                !currentModule?.show_solutions && 'danger'
              }`}
              shape="circle"
              icon={<EyeOutlined />}
              onClick={handleRequestSolution}
              loading={isActivityLoading && isActivityLoading === activity?.id}
              disabled={!isAttemptActive || isActivityLoading || !canExecute}
            />
          </Tooltip>
        )}
    </div>
  )

  // TODO: don't keep using isReview as we now have Teams
  const getSubmitInfoText = () => {
    if (!isAttemptActive || !hasActiveLabSession || !currentViewIsLoaded)
      return 'Start project before checking your activities.'

    if (!isReview && !isActivityAllowed) return 'Resolve the previous activity first.'

    if (!canViewEarlyAccess) return 'This project is currenly on Early Access.'
    if (!canRunProContent) return 'This project is exclusive for PRO users.'

    if (activity?.input_required && !answer) return 'Write an answer before checking activity.'
  }

  const handleForfeit = () => {
    Modal.confirm({
      className: 'confirm-forfeit-activity-dialog',
      title: 'Are you sure you want to forfeit this activity?',
      content: (
        <>
          <p>
            Once you forfeit an activity, it will be marked as Not actioned, and you won't be able to keep working on it
            later.
          </p>
        </>
      ),
      icon: <WarningFilled />,
      okText: 'Yes, forfeit',
      cancelText: 'Cancel',
      onOk: () => dispatch(skipAssessmentContent('skip', activity?.id)),
      okButtonProps: {
        type: 'primary',
        style: { backgroundColor: '#000' },
      },
      maskClosable: true,
    })
  }

  const getAssessmentActions = () => {
    const wasSkipClicked = activity?.actions?.some((action) => action.type === 'skip')
    const wasNextClicked = activity?.actions?.some((action) => action.type === 'next')
    const activityIsPast = wasSkipClicked || wasNextClicked

    return (
      <div
        className={`assessment-content-actions ${wasSkipClicked ? 'skipped' : isActivitySubmitted ? (isActivityPassed ? 'correct' : 'incorrect') : ''} ${activityIsPast ? 'is-past' : ''}`}
      >
        {isActivitySubmitted ? (
          <span className="status-block">{isActivityPassed ? 'Correct answer' : 'Incorrect answer'}</span>
        ) : activityIsPast ? (
          <span className="status-block">Not actioned</span>
        ) : (
          <></>
        )}
        {!activityIsPast && (
          <div className="actions-container">
            {isActivityPassed && <span />}

            {!wasSkipClicked && !isActivitySubmitted ? (
              <Button className="skip-button" type="default" onClick={handleForfeit}>
                Forfeit
              </Button>
            ) : activity?.user_status && !activityIsPast ? (
              <Button
                className="skip-button"
                type={isActivityPassed ? 'primary' : 'default'}
                onClick={() => dispatch(skipAssessmentContent('next', activity?.id))}
              >
                {isActivityPassed ? 'Continue' : 'Give up'}
              </Button>
            ) : (
              <span />
            )}

            {!isActivityPassed && !activityIsPast && (
              <div className="submit-container">
                <Button
                  type="primary"
                  onClick={handleSubmitActivity}
                  loading={isActivityLoading && isActivityLoading === activity?.id}
                  disabled={
                    (activity?.input_required && !answer) ||
                    !isActivityAllowed ||
                    !isAttemptActive ||
                    isActivityLoading ||
                    !canExecute
                  }
                >
                  {isActivitySubmitted ? 'Check again' : 'Check'}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  useEffect(() => {
    if (!moduleAttempt) return

    setAnswer()
    setInfoBlock()
  }, [moduleAttempt?.id])

  useEffect(() => {
    if (!activity?.id) return

    setAnswer(activity?.user_status?.submitted_answer?.text_answer || '')
  }, [activity?.id])

  return (
    <>
      <div className="activity-container manually-validated-activity">
        <div className="activity-content">
          {activity?.custom_number && <Tag className="activity-tag">{activity?.custom_number}</Tag>}
          <div className="activity-body">
            {!activity?.custom_number && !isAssessment && (
              <span className="activity-number">{(activity?.order || 0) + 1}</span>
            )}

            {activity?.title_html && (
              <div className="title-container" dangerouslySetInnerHTML={{ __html: activity?.title_html }} />
            )}

            {!isReview && isAuthenticated && activityActions}
          </div>

          <Replacement contentHtml={activity?.content_html} customClass={'activity-content-markdown'} />

          {activity?.expected_outcome_html && (
            <div className="activity-expected-outcome-container">
              <p className="section-title">Expected outcome:</p>
              <Replacement contentHtml={activity?.expected_outcome_html} customClass={'activity-expected-outcome'} />
            </div>
          )}
        </div>
      </div>

      {activity?.input_required && (
        <ActivityWidget
          widgetType={activity?.widget_type}
          widgetMetadata={activity?.widget_metadata}
          value={answer}
          onChange={setAnswer}
          disabled={
            isReview || !isActivityAllowed || !isAttemptActive || isActivityPassed || isActivityLoading || !canExecute
          }
        />
      )}

      <div className="review-status-block">
        {isAssessment ? (
          getAssessmentActions()
        ) : (
          <div className="submit-container">
            <span>
              {!isReview && (
                <Button
                  type="primary"
                  onClick={handleSubmitActivity}
                  loading={isActivityLoading && isActivityLoading === activity?.id}
                  disabled={
                    (activity?.input_required && !answer) ||
                    !isActivityAllowed ||
                    !isAttemptActive ||
                    isActivityLoading ||
                    !canExecute
                  }
                >
                  {isActivitySubmitted ? 'Update answer' : 'Mark as done'}
                </Button>
              )}

              {isActivitySubmitted && (
                <>
                  {isActivityPassed === null ? (
                    <span className={`activity-status-btn submitted`}>Answer submitted</span>
                  ) : (
                    <span className={`activity-status-btn ${isActivityReCheckPassed ? 'correct' : 'incorrect'}`}>
                      {isActivityLoading ? null : isActivityReCheckPassed ? 'Correct' : 'Wrong answer'}
                    </span>
                  )}
                </>
              )}
            </span>

            <p className="submit-info">{getSubmitInfoText()}</p>
          </div>
        )}

        {!isReview && activityStderr && <pre className="info-block error">{activityStderr}</pre>}

        {!isReview && activityStdout && <pre className="info-block out">{activityStdout}</pre>}

        {!isReview && activity?.hint_html && infoBlock === 'hint' && (
          <div className="info-block">
            <Replacement contentHtml={activity?.hint_html} />
          </div>
        )}

        {!isReview && activity?.solution_html && shouldViewActivitySolutions && infoBlock === 'solution' && (
          <div className="info-block">
            <Replacement contentHtml={activity?.solution_html} />
          </div>
        )}

        {isReview && !isActivitySubmitted && (
          <Button type="dashed" className={'activity-status-btn not-actioned'}>
            Not actioned
          </Button>
        )}

        {isReview && activityActions}

        <ActivityReactions activity={activity} />

        {uiConfig?.showModuleReportIssue && isAuthenticated && isAttemptActive && (
          <div className="feedback-box">
            <div className="feedback-btn" onClick={() => dispatch(toggleReportIssueModal(activity))}>
              <BugOutlined />
              <span>Report issue</span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ManuallyValidatedActivity
