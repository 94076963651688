import { useSelector, useDispatch } from 'react-redux'
import Button from '@/components/Button'
import ProBadge from '@/assets/images/plans/pro-badge.png'
import { toggleUpgradeToProModal } from '@/store/app/actions'
import { Content } from './styles'

const ProBanner = () => {
  const dispatch = useDispatch()

  const { currentSubscription } = useSelector((state) => state.subscriptions)
  const { currentModule } = useSelector((state) => state.modules)

  const canViewEarlyAccess =
    currentSubscription?.available_features?.early_access_content?.available || !currentModule?.early_access
  const canRunProContent = currentSubscription?.available_features?.pro_content?.available || !currentModule?.is_pro

  const showBanner = !canViewEarlyAccess || !canRunProContent

  const renderContent = () => {
    if (!canViewEarlyAccess) {
      return (
        <div className="text-container">
          <p className="title">
            <img className="pro-badge" src={ProBadge} alt="PRO user" />
            Exclusive Early Access
          </p>

          <p className="text">Enjoy exclusive early access to this project before anybody else.</p>
        </div>
      )
    }

    if (!canRunProContent) {
      return (
        <div className="text-container">
          <p className="title">
            <img className="pro-badge" src={ProBadge} alt="PRO user" />
            PRO project
          </p>

          <p className="text">This project is exclusive for PRO users.</p>
        </div>
      )
    }
  }

  if (!showBanner) return <></>

  return (
    <Content className="pro-banner">
      <div className="content">
        {renderContent()}

        <Button type="primary" onClick={() => dispatch(toggleUpgradeToProModal(true))}>
          Upgrade Now
        </Button>
      </div>
    </Content>
  )
}

export default ProBanner
