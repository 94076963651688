import { Switch, Select } from 'antd'
import { SlideTextSparkle24Regular, SettingsCogMultiple24Regular } from '@fluentui/react-icons'
import activityWidgetTypes from '@/constants/activityWidgetTypes'
import codeLanguages from '@/constants/codeLanguages'
import { Container } from './styles'

const WidgetSelector = ({
  title,
  widgetType,
  setWidgetType,
  widgetCodeLanguage,
  setWidgetCodeLanguage,
  widgetRichTextEnabled,
  setWidgetRichTextEnabled,
}) => {
  return (
    <Container>
      <div className="content">
        {title && <p className="title">{title}</p>}

        <div className="widgets-container">
          {activityWidgetTypes?.map((w) => (
            <div
              key={w.name}
              className={`widget-item ${widgetType === w.code ? 'is-selected' : ''} ${w.comingSoon ? 'is-coming-soon' : ''}`}
              onClick={w.comingSoon ? () => {} : () => setWidgetType(w.code)}
            >
              {w.icon}

              <div className="name-container">
                <p className="widget-name">{w.name}</p>

                {w.comingSoon && <p className="description">coming soon</p>}
              </div>
            </div>
          ))}
        </div>

        {widgetType === 'text' && (
          <div className="widget-option-box">
            <div className="content">
              <div className="title-container">
                <SlideTextSparkle24Regular className="icon" />
                <p className="title">Enable rich text</p>
              </div>
            </div>

            <Switch checked={widgetRichTextEnabled} onChange={setWidgetRichTextEnabled} />
          </div>
        )}

        {widgetType === 'code' && (
          <div className="widget-option-box">
            <div className="content">
              <div className="title-container">
                <SettingsCogMultiple24Regular className="icon" />
                <p className="title">Select language</p>
              </div>
            </div>

            <Select
              className="widget-code-language"
              placeholder="Select language"
              value={widgetCodeLanguage}
              onChange={setWidgetCodeLanguage}
              options={codeLanguages}
            />
          </div>
        )}
      </div>
    </Container>
  )
}

export default WidgetSelector
