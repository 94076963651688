import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { Tag, Skeleton } from 'antd'
import { renderModuleImage } from '@/helpers/renderModuleImage'
import Button from '@/components/Button'
import ProTooltip from '@/components/ProTooltip'
import ProBadge from '@/assets/images/plans/pro-badge.png'
import { Container } from './styles'

const VerticalModuleCard = ({ module, isLoading }) => {
  const { currentSubscription } = useSelector((state) => state.subscriptions)

  const canViewEarlyAccess = currentSubscription?.available_features?.early_access_content?.available
  const canRunProContent = currentSubscription?.available_features?.pro_content?.available

  const isPreview = (module?.early_access && !canViewEarlyAccess) || (module?.is_pro && !canRunProContent)

  return (
    <Container className="vertical-module-card">
      <div className="img-container">
        {isLoading ? <Skeleton.Node className="loading-image" active /> : renderModuleImage(module)}
      </div>

      {!isLoading && module?.is_pro && (
        <ProTooltip
          isLarge
          title={!canRunProContent && 'Exclusive for PRO users'}
          content={
            <>
              <p className="text">This project is exclusive for PRO users.</p>
              <p className="text">
                <b>Upgrade to PRO</b> to access this project!
              </p>
            </>
          }
          showUpgradeButton
        >
          <div className="pro-badge-container">
            <img className="pro-badge" src={ProBadge} alt="PRO user" />
            <b>PRO</b>
          </div>
        </ProTooltip>
      )}

      {!isLoading && module?.early_access && (
        <ProTooltip
          isLarge
          title={!canViewEarlyAccess && 'Exclusive Early Access'}
          content={
            <>
              <p className="text">
                This project will become available to Basic users on{' '}
                <b>{dayjs(module?.general_available_at).format('MMMM Do')}</b>.
              </p>
              <p className="text">
                <b>Upgrade to PRO</b> to access this project before anybody else!
              </p>
            </>
          }
          showUpgradeButton
        >
          <div className="pro-badge-container">
            <img className="pro-badge" src={ProBadge} alt="PRO user" />
            <span>Early Access</span>
          </div>
        </ProTooltip>
      )}

      <div className="module-info">
        {isLoading ? (
          <Skeleton className="loading-title" active />
        ) : (
          <>
            <div className="info-box">
              <Tag className={`tag difficulty-${module?.difficulty}`}>{module?.difficulty}</Tag>
              {module?.learning_area && <span className="text">{module?.learning_area?.name}</span>}
            </div>

            <p className="title">{module?.name}</p>
          </>
        )}

        {!isLoading && (
          <Link className="link" to={`/project/${module?.id}`}>
            <Button className="main-btn" type="primary">
              {isPreview ? 'Preview' : 'Start'} project
            </Button>
          </Link>
        )}
      </div>
    </Container>
  )
}

export default VerticalModuleCard
