import { useSelector } from 'react-redux'
import {
  Battery224Regular,
  Battery524Regular,
  Battery824Regular,
  Rocket24Regular,
  DeveloperBoard24Regular,
  Storage24Regular,
} from '@fluentui/react-icons'
import { capitalize } from '@/utils/functions'
import { Container } from './styles'

import { Progress } from 'antd'

const StackSizeCard = ({ hasBorder }) => {
  const { currentCustomModule, currentCustomModuleVersion } = useSelector((state) => state.customModules)

  const storageLimit = currentCustomModule?.storage_limit
  const storagePercUsed = (currentCustomModule?.total_size_in_mb / storageLimit) * 100
  const storageStatus = storagePercUsed >= 75 ? 'high' : storagePercUsed > 40 ? 'medium' : 'low'

  return (
    <Container className="stack-size-card" $hasBorder={hasBorder}>
      <div className="header">
        <div className="image-container">
          {currentCustomModuleVersion?.stack_size?.name === 'small' && <Battery224Regular />}
          {currentCustomModuleVersion?.stack_size?.name === 'medium' && <Battery524Regular />}
          {currentCustomModuleVersion?.stack_size?.name === 'large' && <Battery824Regular />}
        </div>

        <h5 className="name">{capitalize(currentCustomModuleVersion?.stack_size?.name)}</h5>
      </div>

      <div className="value-boxes-container">
        <div className="value-box">
          <p className="key">
            <Rocket24Regular className="icon" />
            RAM
          </p>

          <p className="value">{currentCustomModuleVersion?.stack_size?.ram?.human}</p>
        </div>

        <div className="value-box">
          <p className="key">
            <DeveloperBoard24Regular className="icon" />
            CPU
          </p>

          <p className="value">{currentCustomModuleVersion?.stack_size?.cpu?.human}</p>
        </div>

        <div className={`value-box ${storageStatus}`}>
          <p className="key">
            <Storage24Regular className="icon" />
            Storage ({storagePercUsed.toFixed(2)}% used)
          </p>

          <p className="value">{storageLimit}MB</p>

          <Progress
            className="progress-container"
            percent={storagePercUsed}
            showInfo={false}
            size={['100%', 4]}
            strokeLinecap="square"
          />
        </div>
      </div>
    </Container>
  )
}

export default StackSizeCard
