import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { Switch, Collapse, Form, Tooltip } from 'antd'
import { EyeRegular, DeleteRegular, TextChangeCase24Regular } from '@fluentui/react-icons'
import Button from '@/components/Button'
import Input from '@/components/Input'
import MarkdownEditor from '@/components/MarkdownEditor'
import { showToast } from '@/utils/toast'
import {
  addPageContent,
  updatePageContent,
  updateCustomModuleVersion,
  setActivityWizardModalOpen,
} from '@/store/customModules/actions'
import { Container } from '../styles'

const InputActivityForm = ({ activityTypeName, icon }) => {
  const dispatch = useDispatch()

  const { currentCustomModuleVersion, mode, isActivityWizardModalOpen, isCustomModulesLoading } = useSelector(
    (state) => state.customModules,
  )
  const sectionId = isActivityWizardModalOpen?.sectionId
  const activity = isActivityWizardModalOpen?.activity
  const isEditing = !!activity?.id

  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [answers, setAnswers] = useState([])
  const [isCaseSensitive, setIsCaseSensitive] = useState(false)
  const [solution, setSolution] = useState('')
  const [hint, setHint] = useState('')

  const [form] = Form.useForm()

  const addAnswer = () => {
    setAnswers([...answers, ''])
  }

  const removeAnswer = (index) => {
    const newAnswers = answers.filter((_, i) => i !== index)
    setAnswers(newAnswers)
  }

  const updateAnswer = (index, data) => {
    const newAnswers = [...answers]
    newAnswers[index] = data
    setAnswers(newAnswers)
  }

  const renderAnswers = () => {
    return answers.map((a, index) => (
      <div key={index} className="answer-box">
        <div className="answer-container">
          <Input
            value={a}
            placeholder={`Answer ${index + 1}`}
            onChange={(evt) => updateAnswer(index, evt.target.value)}
          />
        </div>

        {!(isEditing && mode === 'amend') && (
          <div className="actions">
            <Tooltip title="Remove answer">
              <div className="icon-container" onClick={() => removeAnswer(index)}>
                <DeleteRegular className="icon" />
              </div>
            </Tooltip>
          </div>
        )}
      </div>
    ))
  }

  const handleSaveActivity = async () => {
    try {
      await form.validateFields()
    } catch (error) {
      showToast('Please fill all required fields.', 'error')
      return
    }

    if (!answers.filter((a) => a !== '').length) {
      showToast('Please add at least one answer.', 'error')
      return
    }

    let activityBlock = {
      id: isEditing ? activity?.id : uuidv4(),
      type: 'input',
      title_md: title,
      content_md: content,
      //
      correct_answers: answers,
      case_sensitive: isCaseSensitive,
      //
      expected_outcome_md: '',
      solution_md: solution,
      hint_md: hint,
    }

    let updatedContent
    if (isEditing) {
      updatedContent = await dispatch(updatePageContent(sectionId, activityBlock))
      showToast('Activity updated successfully!')
    } else {
      updatedContent = await dispatch(addPageContent(sectionId, activityBlock))
      showToast('Activity created successfully!')
    }

    if (mode === 'edit') {
      dispatch(
        updateCustomModuleVersion(
          currentCustomModuleVersion?.custom_module?.id,
          currentCustomModuleVersion?.id,
          { content_json: updatedContent },
          () => {},
          (err) => showToast(err, 'error'),
        ),
      )
    }

    dispatch(setActivityWizardModalOpen(false))
  }

  useEffect(() => {
    if (!activity) return

    setTitle(activity?.title_md)
    setContent(activity?.content_md)
    setAnswers(activity?.correct_answers)
    setIsCaseSensitive(activity?.case_sensitive)
    setSolution(activity?.solution_md)
    setHint(activity?.hint_md)

    form.setFieldsValue({
      Title: activity?.title_md,
    })
  }, [activity])

  return (
    <Container className="input-activity-form">
      <div className="header">
        <div className="icon-container">{icon}</div>
        <h4 className="title">
          {isEditing ? 'Editing' : ''} {activityTypeName} activity
        </h4>
      </div>

      <Form className="activity-form" form={form} name="activity-form">
        <div className="activity-form-container">
          <Form.Item name="Title" initialValue={activity?.title_md} rules={[{ required: true }]}>
            <Input
              className="activity-title"
              label="Activity title"
              value={title}
              placeholder="Activity title"
              size="large"
              onChange={(evt) => setTitle(evt.target.value)}
            />
          </Form.Item>

          <MarkdownEditor
            label="Activity description and instructions"
            content={content}
            placeholder="Activity description and instructions"
            onChange={setContent}
          />

          <hr />

          <div className="answers-container">
            <div className="header">
              <p className="title">Add correct answers for your question:</p>
            </div>

            {renderAnswers()}

            {!(isEditing && mode === 'amend') && (
              <Button className="add-button" type="default" onClick={addAnswer}>
                + add option
              </Button>
            )}
          </div>

          <div className="option-box">
            <div className="content">
              <div className="title-container">
                <TextChangeCase24Regular className="icon" />
                <p className="title">Answers are case sensitive?</p>
              </div>

              <Switch checked={isCaseSensitive} onChange={setIsCaseSensitive} />
            </div>
          </div>

          <hr />

          <Collapse
            className="advanced-options-collapse"
            ghost
            expandIconPosition="end"
            items={[
              {
                key: '1',
                label: 'Show advanced options',
                children: (
                  <div className="advanced-options-container">
                    <MarkdownEditor label="Solution" content={solution} placeholder="Solution" onChange={setSolution} />
                    <MarkdownEditor label="Hints" content={hint} placeholder="Hints" onChange={setHint} />
                  </div>
                ),
              },
            ]}
          />
        </div>

        <div className="actions">
          <Button type="default" onClick={() => dispatch(setActivityWizardModalOpen(false))}>
            Cancel
          </Button>

          <Button type="primary" onClick={handleSaveActivity} loading={isCustomModulesLoading}>
            {isEditing ? 'Update' : 'Create'}
          </Button>
        </div>
      </Form>
    </Container>
  )
}

export default InputActivityForm
