import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { Input } from 'antd'
import { SmileTwoTone, LockTwoTone } from '@ant-design/icons'
import { isDataWarsHostName } from '@/helpers/env'
import useQueryParam from '@/hooks/useQueryParam'
import OauthBox from '@/components/auth/OauthBox'
import Button from '@/components/Button'
import { login } from '@/store/users/actions'
import { LogoHeader, Form } from '../styles'

const LoginForm = ({ toggleMode, callback }) => {
  const dispatch = useDispatch()

  const [defaultEmail] = useQueryParam('email')

  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const { currentAccount } = useSelector((state) => state.accounts)
  const { isAuthModalOpen, isLoading } = useSelector((state) => state.users)

  const orgConfig = currentAccount?.organization?.config

  const onSubmit = async (data) => {
    await dispatch(login({ ...data, domain: 'datawars' }, callback))
  }

  useEffect(() => {
    if (!defaultEmail) return

    setValue('username', defaultEmail, { shouldValidate: true, shouldDirty: true })
  }, [defaultEmail]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={handleSubmit(onSubmit)}>
      <LogoHeader>
        <h3 className="title">Login to your account</h3>
      </LogoHeader>

      {(isDataWarsHostName || orgConfig?.enable_google_sign_in) && (
        <OauthBox action="login" text="Sign in with Google" />
      )}

      <Controller
        name="username"
        defaultValue=""
        control={control}
        rules={{ required: 'This field is required' }}
        render={({ field }) => (
          <Input
            {...field}
            type="text"
            size="large"
            prefix={<SmileTwoTone />}
            placeholder="Username or Email address"
            disabled={defaultEmail}
          />
        )}
      />
      {errors.username && <p className="error-text">{errors.username.message}</p>}

      <Controller
        name="password"
        defaultValue=""
        control={control}
        rules={{ required: 'This field is required' }}
        render={({ field }) => (
          <Input.Password {...field} type="password" size="large" placeholder="Password" prefix={<LockTwoTone />} />
        )}
      />
      {errors.password && <p className="error-text">{errors.password.message}</p>}

      <Button type="primary" size="large" htmlType="submit" loading={isLoading}>
        Sign in
      </Button>

      {isDataWarsHostName && (
        <p className="bottom-text">
          You don't have an account?{' '}
          {isAuthModalOpen ? (
            <>
              <a className="link" onClick={() => toggleMode('signup')}>
                Register here
              </a>

              <br />

              {(isDataWarsHostName || orgConfig?.enable_password_reset) && (
                <a className="link" onClick={() => toggleMode('forgotPassword')}>
                  Forgot your password?
                </a>
              )}
            </>
          ) : (
            <>
              <Link className="link" to={`/register${window?.location?.search}`}>
                Register here
              </Link>

              <br />

              {(isDataWarsHostName || orgConfig?.enable_password_reset) && (
                <Link className="link" to={`/forgot-password${window?.location?.search}`}>
                  Forgot your password?
                </Link>
              )}
            </>
          )}
        </p>
      )}
    </Form>
  )
}

export default LoginForm
