import dayjs from 'dayjs'
import { SET_ACTIVITIES_LOADING, TOGGLE_REPORT_ISSUE_MODAL, SET_ACTIVITIES_ERROR } from '@/store/activities'
import { CONTENT_JSON_UPDATED } from '@/store/customModules'
import { MODULE_UPDATED, MODULE_CONTENT_STREAM_UPDATED } from '@/store/modules'
import { SET_LAST_LAB_ACTION_DATE } from '@/store/labs'
import {
  postActivityAttempt,
  postActivityCheck,
  postActivityHint,
  postActivitySolution,
  postActivityReaction,
  deleteActivityReaction,
} from '@/services/activities'
// import { postLtiScore } from '@/services/lti'
import { showToast } from '@/utils/toast'

const createActivityAttempt = (data) => async (dispatch, getState) => {
  // data = { activityId, moduleAttemptId, labSessionId, textAnswer, selectedAnswer, submittedAnswer }
  try {
    dispatch(SET_ACTIVITIES_LOADING(data?.activityId))

    const { modules, labs } = getState()
    const { currentModule: module, moduleContentStream } = modules
    const moduleAttempt = module?.user_status?.last_module_attempt

    const { currentLab } = labs
    const labSessionId = currentLab?.allocated_session?.id

    const body = {
      moduleAttemptId: moduleAttempt.id,
      labSessionId,
      ...data,
    }
    const responseObj = await postActivityAttempt(body)
    const { activity_attempt, grading, module_attempt, skill_finished, skill_track_finished } = responseObj

    const isJustFinished = moduleAttempt?.user_status?.scoring !== 1 && module_attempt?.scoring === 1

    let auxModule = {
      ...module,
      module_pages: module.module_pages?.map((p) => {
        const auxPage = {
          ...p,
          activities: p.activities?.map((a) => {
            if (a.id === activity_attempt.activity) {
              return {
                ...a,
                user_status: {
                  ...activity_attempt,
                  grading,
                },
              }
            }

            return a
          }),
        }

        return auxPage
      }),
      user_status: {
        ...module.user_status,
        last_module_attempt: {
          ...module.user_status.last_module_attempt,
          status: module_attempt?.scoring === 1 ? 'finished' : 'started',
          user_status: module_attempt,
          // last_activity_at: activity_attempt.submitted_answer_at, //TODO: VER SI TIENE SENTIDO O SE USA PARA TERMINADAS
        },
        last_finished_at: isJustFinished ? dayjs().toISOString() : module?.user_status?.last_finished_at,
      },
      skillFinished: skill_finished,
      skillTrackFinished: skill_track_finished,
    }

    // module just finished
    auxModule.justFinished = false
    if (isJustFinished) {
      auxModule.justFinished = true
    }

    dispatch(MODULE_UPDATED(auxModule))

    if (module.module_type === 'assessment') {
      const auxModuleContentStream = moduleContentStream?.map((c) => {
        if (c.id === activity_attempt.activity) {
          return {
            ...c,
            user_status: {
              ...activity_attempt,
              grading,
            },
          }
        }

        return c
      })

      dispatch(MODULE_CONTENT_STREAM_UPDATED(auxModuleContentStream))
    }

    dispatch(SET_ACTIVITIES_LOADING(false))

    if (currentLab?.allocated_session?.status === 'allocated') {
      dispatch(SET_LAST_LAB_ACTION_DATE(dayjs().toISOString()))
    }

    // if module progress is 100%, send score via LTI
    // if (launchId && module_attempt?.progress === 1) {
    // await postLtiScore(launchId)
    //   .then((res) => showToast('Score reported back to LMS'))
    //   .catch((err) => showToast('Failed reporting score back to LMS', 'error'))
    // }
  } catch (error) {
    const { message } = error
    dispatch(SET_ACTIVITIES_ERROR(message))
    showToast(error.message, 'error')
  }
}

const createActivityCheck = (data) => async (dispatch, getState) => {
  // data = { activityId, textAnswer, selectedAnswer, submittedAnswer }
  try {
    dispatch(SET_ACTIVITIES_LOADING(data?.activityId))

    const { customModules, labs } = getState()

    const { contentJson } = customModules
    const { currentLab } = labs
    const labSessionId = currentLab?.allocated_session?.id

    const body = {
      labSessionId,
      ...data,
    }
    const checkInfo = await postActivityCheck(body)
    const auxContentJson = contentJson.map((p) => {
      const auxPage = {
        ...p,
        content: p.content.map((a) => {
          if (a.id === data.activityId) {
            return {
              ...a,
              ...checkInfo,
            }
          }

          return a
        }),
      }

      return auxPage
    })
    dispatch(CONTENT_JSON_UPDATED(auxContentJson))

    dispatch(SET_ACTIVITIES_LOADING(false))
  } catch (error) {
    const { message } = error
    dispatch(SET_ACTIVITIES_ERROR(message))
    showToast(error.message, 'error')
  }
}

const createActivityHint = (data) => async (dispatch, getState) => {
  // data = { activityId }
  try {
    dispatch(SET_ACTIVITIES_LOADING(data?.activityId))

    const { modules, labs } = getState()
    const { currentModule: module } = modules
    const { user_status: { last_module_attempt: moduleAttempt } = {} } = module

    const { currentLab } = labs

    const body = {
      activityId: data.activityId,
      moduleAttemptId: moduleAttempt.id,
    }
    const responseObj = await postActivityHint(body)
    const { hint_html } = responseObj

    let auxModule = {
      ...module,
      module_pages: module.module_pages?.map((p) => {
        const auxPage = {
          ...p,
          activities: p.activities?.map((a) => {
            if (a.id === data.activityId) {
              return {
                ...a,
                hint_html,
              }
            }

            return a
          }),
        }

        return auxPage
      }),
    }

    dispatch(MODULE_UPDATED(auxModule))
    dispatch(SET_ACTIVITIES_LOADING(false))

    if (currentLab?.allocated_session?.status === 'allocated') {
      dispatch(SET_LAST_LAB_ACTION_DATE(dayjs().toISOString()))
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_ACTIVITIES_ERROR(message))
    showToast(error.message, 'error')
  }
}

const createActivitySolution = (data) => async (dispatch, getState) => {
  // data = { activityId }
  try {
    dispatch(SET_ACTIVITIES_LOADING(data?.activityId))

    const { modules, labs } = getState()
    const { currentModule: module } = modules
    const { user_status: { last_module_attempt: moduleAttempt } = {} } = module

    const { currentLab } = labs

    const body = {
      activityId: data.activityId,
      moduleAttemptId: moduleAttempt.id,
    }
    const responseObj = await postActivitySolution(body)
    const { solution_html } = responseObj

    let auxModule = {
      ...module,
      module_pages: module.module_pages?.map((p) => {
        const auxPage = {
          ...p,
          activities: p.activities?.map((a) => {
            if (a.id === data.activityId) {
              return {
                ...a,
                solution_html,
              }
            }

            return a
          }),
        }

        return auxPage
      }),
    }

    dispatch(MODULE_UPDATED(auxModule))
    dispatch(SET_ACTIVITIES_LOADING(false))

    if (currentLab?.allocated_session?.status === 'allocated') {
      dispatch(SET_LAST_LAB_ACTION_DATE(dayjs().toISOString()))
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_ACTIVITIES_ERROR(message))
    showToast(error.message, 'error')
  }
}

const createActivityReaction = (activityId, data) => async (dispatch) => {
  try {
    dispatch(SET_ACTIVITIES_LOADING(true))

    await postActivityReaction(activityId, data)
  } catch (error) {
    const { message } = error
    showToast(message, 'error')
  } finally {
    dispatch(SET_ACTIVITIES_LOADING(false))
  }
}

const removeActivityReaction = (activityId, data) => async (dispatch) => {
  try {
    dispatch(SET_ACTIVITIES_LOADING(true))

    await deleteActivityReaction(activityId, data)
  } catch (error) {
    const { message } = error
    showToast(message, 'error')
  } finally {
    dispatch(SET_ACTIVITIES_LOADING(false))
  }
}

const toggleReportIssueModal = (data) => (dispatch) => {
  dispatch(TOGGLE_REPORT_ISSUE_MODAL(data))
}

export {
  createActivityAttempt,
  createActivityCheck,
  createActivityHint,
  createActivitySolution,
  createActivityReaction,
  removeActivityReaction,
  toggleReportIssueModal,
}
