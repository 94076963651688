const codeLanguages = [
  { value: 'python', label: 'Python' },
  { value: 'r', label: 'R' },
  { value: 'sql', label: 'SQL' },
  { value: 'shell', label: 'Shell' },
  { value: 'julia', label: 'Julia' },
  { value: 'java', label: 'Java' },
]

export default codeLanguages
