import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.gray1};
  display: flex;
  flex-direction: column;

  > .content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    overflow: auto;
    padding: 24px;
  }
`
