import {
  TextAddT24Regular,
  TaskListSquareAdd24Regular,
  Code24Regular,
  HandDraw24Regular,
  MathFormatProfessional24Regular,
  Sparkle24Filled,
} from '@fluentui/react-icons'

const activityTypes = [
  {
    name: 'Strict answer',
    type: 'input',
    icon: <TextAddT24Regular className="activity-type-icon" />,
    description:
      'The student enters a single textual answer that is compared against your provided answer. Must be a perfect match.',
  },
  {
    name: 'Multiple choice',
    type: 'multiple_choice',
    icon: <TaskListSquareAdd24Regular className="activity-type-icon" />,
    description:
      'A question is presented with several possible answers the student has to select from. Accepts one or many correct answers.',
  },
  {
    name: 'Code validated',
    type: 'code_validated',
    icon: <Code24Regular className="activity-type-icon" />,
    description: 'A coding task evaluated automatically based on predefined criteria or test cases.',
  },
  {
    name: 'Manually graded',
    type: 'manually_validated',
    icon: <HandDraw24Regular className="activity-type-icon" />,
    description: 'An activity without autograding that has to be manually graded once the student submits the project.',
  },
  {
    name: 'Arithmetic Activity (LaTeX)',
    type: 'latex_validated',
    icon: <MathFormatProfessional24Regular className="activity-type-icon" />,
    description:
      'The student provides an arithmetic expression (using LaTeX) that is checked against your correct answer',
  },
  {
    name: 'AI Autograded',
    type: 'ai_validated',
    icon: <Sparkle24Filled className="activity-type-icon" />,
    description:
      'An LLM-powered AI assistant evaluates and automatically grades the student answer based on your criteria',
    highlight: true,
  },
]

export default activityTypes
