import styled, { css, keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`

export const Container = styled.div`
  position: relative;
  padding: 10px 0px;

  &.is-dragging {
    opacity: 0.4;
  }

  > .options-header {
    height: 0px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.07);

    .options {
      background-color: ${(props) => props.theme.colors.gray1};
      display: flex;

      .icon-container {
        height: 28px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid ${(props) => props.theme.colors.gray4};
        margin: 0px;
        cursor: pointer;
        user-select: none;

        &:last-child {
          border: none;
          padding-right: 0px;
        }

        > .icon {
          height: 18px;
          width: 18px;
        }

        .drag-button {
          background-color: transparent;

          svg {
            height: 28px;
            width: 28px;
          }
        }

        &:hover {
          background-color: ${(props) => props.theme.colors.gray4};
        }
      }
    }

    .tag {
      background-color: ${(props) => props.theme.colors.geekblue2};
      color: ${(props) => props.theme.colors.gray11};
      border-radius: 2px 2px 0px 0px;
      font-size: 12px;
      line-height: 20px;
      padding: 3px 6px;
      margin: 0px;
    }
  }

  ${(props) =>
    !props.$readOnly &&
    css`
      > .options-header {
        height: 26px;
        opacity: 1;
        visibility: visible;
      }
    `}

  > .content-container {
    background: ${(props) => props.theme.colors.gray1};
    position: relative;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px 16px;
    box-shadow: inset 0px 2px 0px ${(props) => props.theme.colors.gray7};

    .ai-graded-tag {
      width: fit-content;
      background:
        radial-gradient(217.52% 153.59% at 122.4% 118.47%, #9196ff 47.5%, #ac8fff 73%, #9eb4fe 94.27%), #f0f2f5;
      color: ${(props) => props.theme.colors.gray1};
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 12px;
      border-radius: 25px;
      padding: 5px 14px;

      .icon {
        height: 20px;
        width: 20px;
      }
    }

    .mdxeditor {
      background-color: transparent;
    }

    .activity-preview-container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .activity-body {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .activity-number {
          color: ${(props) => props.theme.colors.gray10};
          height: 30px;
          max-width: 30px;
          width: 100%;
          background-color: ${(props) => props.theme.colors.gray4};
          font-size: 12px;
          font-weight: 500;
          display: flex;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          margin-top: -2px;
        }

        .title-container {
          flex: 1;

          .title {
            color: ${(props) => props.theme.colors.gray10};
            font-size: 15px;
            font-weight: 700;
            line-height: 22px;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 0px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        .activity-actions {
          display: flex;
          justify-content: space-between;
          gap: 6px;

          button {
            background-color: transparent;

            &.is-selected {
              background-color: ${(props) => props.theme.colors.geekblue2};
              color: ${(props) => props.theme.colors.gray10};
              border: none;
            }

            &:disabled {
              background-color: ${(props) => props.theme.colors.gray4};
              color: ${(props) => props.theme.colors.gray6};
              border-color: ${(props) => props.theme.colors.gray5};
            }
          }

          .snippet-btn {
            color: ${(props) => props.theme.colors.gray10};
            background-color: ${(props) => props.theme.colors.gray3};
            border-color: transparent;
            cursor: default;

            &:hover {
              color: ${(props) => props.theme.colors.gray10};
              border-color: transparent;
            }

            svg {
              height: 18px;
              width: 18px;
            }
          }
        }
      }

      .activity-content-markdown {
        max-height: 80px;
        overflow: auto;

        p {
          color: ${(props) => props.theme.colors.gray7};
          margin: 0px;
        }
      }

      .view-more {
        color: ${(props) => props.theme.colors.gray11};
        display: block;
        text-decoration: underline;
        text-align: right;
      }
    }

    .submit-container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .input-content {
        .activity-input {
          color: ${(props) => props.theme.colors.gray12};
          background-color: ${(props) => props.theme.colors.gray1};
        }

        .activity-answers-group {
          display: flex;
          flex-direction: column;
          animation: 1s ${fadeInAnimation};

          > label {
            width: 100%;
            margin-bottom: 8px;

            > span:nth-child(2) {
              width: 100%;
              overflow: auto;
            }

            p {
              margin: 0px;
            }
          }
        }
      }

      .actions-content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .submit-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 12px;
        }
      }
    }

    .info-block {
      width: 100%;
      background-color: ${(props) => props.theme.colors.geekblue1};
      border-radius: 6px;
      overflow: auto;
      padding: 12px 26px;

      .mdxeditor {
        ._codeMirrorToolbar_uazmk_408 {
          display: none;
        }

        .cm-editor {
          background-color: transparent;
        }
      }

      &.out {
        background-color: ${(props) => props.theme.colors.gray3};
        margin: 0px;
      }

      &.error {
        background-color: ${(props) => props.theme.colors.red1};
        color: ${(props) => props.theme.colors.red5};
        margin: 0px;
      }
    }
  }

  .tag {
    background-color: ${(props) => props.theme.colors.gray4};
    color: ${(props) => props.theme.colors.gray6};
    border-radius: 6px;
    font-size: 12px;
    line-height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    padding: 4px 6px;

    > .icon,
    > .image {
      height: 18px;
      width: 18px;
    }

    &.default {
      background-color: ${(props) => props.theme.colors.gray3};
      color: ${(props) => props.theme.colors.gray10};

      > .icon {
        color: ${(props) => props.theme.colors.gray10};
      }

      > .image {
        opacity: 0.4;
      }
    }

    &.info {
      background-color: ${(props) => props.theme.colors.geekblue2};
      color: ${(props) => props.theme.colors.geekblue6};

      > .icon {
        color: ${(props) => props.theme.colors.geekblue4};
      }
    }

    &.success {
      background-color: ${(props) => props.theme.colors.cyan2};
      color: ${(props) => props.theme.colors.cyan7};

      > .icon {
        color: ${(props) => props.theme.colors.cyan5};
      }
    }

    &.error {
      background-color: ${(props) => props.theme.colors.red2};
      color: ${(props) => props.theme.colors.red5};

      > .icon {
        color: ${(props) => props.theme.colors.red5};
      }
    }
  }
`
