import styled, { css } from 'styled-components'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  overflow: hidden;
  padding: 0px 14px;

  > .text-container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0px;

    .icon {
      height: 20px;
      width: 20px;
    }
  }

  > .actions {
    display: flex;
    align-items: center;
    gap: 10px;

    > .button {
      height: 30px;
      font-weight: 400;
      border-radius: 6px !important;
      padding: 4px 10px;

      .icon {
        height: 20px;
        width: 20px;
      }

      &.power-button {
        background-color: ${(props) => props.theme.colors.gray3};
        color: ${(props) => props.theme.colors.geekblue10};
        border: none;
        border-radius: 6px;

        .ant-btn-icon {
          display: flex;

          svg {
            height: 20px;
            width: 20px;
          }
        }

        &.on {
          color: ${(props) => props.theme.colors.red5};

          svg {
            color: ${(props) => props.theme.colors.red5};
          }

          &:hover {
            background-color: ${(props) => props.theme.colors.gray3} !important;
            color: ${(props) => props.theme.colors.red5} !important;
            opacity: 1 !important;
            box-shadow:
              2px 2px 2px 0px rgba(61, 85, 110, 0.3) inset,
              -1px -1px 4px 0px #fff inset;
          }
        }

        &.off {
          svg {
            color: ${(props) => props.theme.colors.cyan5};
          }

          &:hover {
            background-color: ${(props) => props.theme.colors.gray3} !important;
            color: ${(props) => props.theme.colors.geekblue10} !important;
            opacity: 1 !important;
            box-shadow:
              2px 2px 2px 0px rgba(61, 85, 110, 0.3),
              -1px -1px 4px 0px #fff;
          }
        }
      }

      &.save-button {
        background-color: ${(props) => props.theme.colors.gray3} !important;
        border: none;
      }

      &.publish-button {
        .ant-btn-icon {
          display: flex;

          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    .autosave-container {
      height: 30px;
      background-color: ${(props) => props.theme.colors.gray3};
      display: flex;
      align-items: center;
      gap: 4px;
      border-radius: 14px;
      padding: 4px 8px;

      .ant-switch {
        background-color: ${(props) => props.theme.colors.gray6};

        &.ant-switch-checked {
          background-color: ${(props) => props.theme.colors.blue5};
        }
      }
    }
  }

  ${(props) =>
    props.$mode === 'browse' &&
    css`
      background-color: ${(props) => props.theme.colors.geekblue3};
    `}

  ${(props) =>
    props.$mode === 'preview' &&
    css`
      background-color: ${(props) => props.theme.colors.purple3};
    `}

  ${(props) =>
    props.$mode === 'edit' &&
    css`
      background-color: ${(props) => props.theme.colors.cyan3};
    `}

  ${(props) =>
    props.$mode === 'amend' &&
    css`
      background-color: ${(props) => props.theme.colors.gold3};
    `}
`
