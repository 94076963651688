import { Popover } from 'antd'
import { Link } from 'react-router-dom'
import { Building24Regular, Location24Regular } from '@fluentui/react-icons'
import { getFlagURL, profilesURL } from '@/helpers/env'
import { capitalize } from '@/utils/functions'
import countries from '@/assets/data/countries.json'
import ProfilePicture from '@/components/ProfilePicture'
import Button from '@/components/Button'
import { Container } from './styles'

const UserPopoverContent = ({ user }) => {
  const isPro = user?.is_pro

  return (
    <Container>
      <div className="header">
        <div className="image-container">
          <ProfilePicture
            src={user?.avatar_url}
            alt={capitalize(user?.first_name)}
            isPro={isPro}
            size="large"
            disableTooltip
          />
        </div>

        <div className="title-container">
          <h5 className="title">
            {capitalize(user?.first_name)} {capitalize(user?.last_name)}
          </h5>
        </div>
      </div>

      {(user?.role || user?.company || user?.declared_location) && (
        <div className="user-info">
          {!!user?.role?.length && !!user?.company?.length && (
            <div className="item">
              <Building24Regular className="icon" />
              <p className="label">
                {user?.role} @ {user?.company}
              </p>
            </div>
          )}

          {!!user?.declared_location?.length && (
            <div className="item">
              <Location24Regular className="icon" />
              <p className="label">{user?.declared_location}</p>

              <img
                className="flag-image"
                src={getFlagURL(countries?.find((c) => c.country === user?.declared_location)?.code)}
                alt={user?.declared_location}
              />
            </div>
          )}
        </div>
      )}

      <div className="actions">
        <Link to={`${profilesURL}${user?.username}`} target="_blank">
          <Button type="secondary">See Profile</Button>
        </Link>
      </div>
    </Container>
  )
}

const UserPopover = ({ user, children }) => {
  return (
    <Popover content={<UserPopoverContent user={user} />} color="#ffffff">
      {children}
    </Popover>
  )
}

export default UserPopover
