import axios from 'axios'
import { apiURL } from '@/helpers/env'
import store from '@/store'

const API = axios.create({
  baseURL: apiURL,
  withCredentials: true,
  headers: {
    common: {
      'Content-Type': 'application/json',
    },
  },
  emulateJSON: true,
})

const isLaunchURL = window.location.pathname?.startsWith('/launch/')

if (isLaunchURL) {
  API.interceptors.request.use((config) => {
    const state = store.getState()
    const launchId = state?.launch?.launchData?.id
    config.headers['X-Launch-ID'] = launchId

    return config
  })
}

API.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response) {
      const skipRedirectUrls = [
        'users/signup',
        'users/login',
        'users/auth/google-oauth2',
        'users/me',
        'subscriptions/me',
        'vouchers/validate',
      ]
      if ([401, 403].includes(error.response.status) && !skipRedirectUrls.includes(error.config.url)) {
        return window.location.replace('/login')
      }
    }

    return Promise.reject(error)
  },
)

export default API
