import styled, { css } from 'styled-components'

export const Container = styled.div`
  > .header {
    display: flex;
    justify-content: space-between;

    .label {
      height: 0px;
      color: ${(props) => props.theme.colors.gray7};
      font-size: 12px;
      font-weight: 500;
      visibility: hidden;
      opacity: 0;
      margin: 0px;
      transition: all 0.3s;
      user-select: none;
    }

    ${(props) =>
      (props.$hasContent || props.$hasInfo || props?.$fixedLabel) &&
      css`
        .label {
          height: 22px;
          visibility: visible;
          opacity: 1;
        }
      `}

    ${(props) =>
      props.$hasInfo &&
      css`
        margin-bottom: 6px;

        .label {
          font-size: 14px;
          font-weight: 400;
          visibility: visible;
          opacity: 1;
        }
      `}

    .info-icon {
      height: 20px;
      width: 20px;
      color: ${(props) => props.theme.colors.gray7};
      cursor: pointer;
    }
  }

  .mdxeditor {
    background-color: ${(props) => props.theme.colors.gray1};
    border: 1px solid ${(props) => props.theme.colors.gray5};
    border-radius: 2px;

    ${(props) =>
      props.$readOnly &&
      css`
        border: none;

        .mdxeditor-toolbar {
          display: none;
        }

        ._contentEditable_uazmk_379 {
          padding: 0px;
        }

        ._iconButton_uazmk_456 {
          display: none;
        }
      `}

    .mdxeditor-toolbar {
      background-color: ${(props) => props.theme.colors.gray1};
      border-radius: 8px;
      padding: 4px 0px;
      overflow-y: hidden;
      transition: all 0.2s ease-in-out;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
      backdrop-filter: blur(10px);
      margin: 4px 10px 0px;

      ${(props) =>
        !props.$isToolbarFixed &&
        css`
          height: 0px;
          visibility: hidden;
          opacity: 0;
        `}

      button {
        font-size: 12px;

        svg {
          width: 18px;
          height: 18px;
        }
      }
    }

    .mdxeditor-root-contenteditable {
      ._placeholder_uazmk_1084 > p {
        color: ${(props) => props.theme.colors.gray6};
      }
    }
  }

  ${(props) =>
    !props.$isToolbarFixed &&
    props.$isFocused &&
    css`
      .mdxeditor {
        border: 1px solid ${(props) => props.theme.colors.geekblue3};

        .mdxeditor-toolbar {
          height: 40px;
          visibility: visible;
          opacity: 1;
        }
      }
    `}
`
