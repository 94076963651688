import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { Collapse, Form } from 'antd'
import { showToast } from '@/utils/toast'
import activityWidgetTypes from '@/constants/activityWidgetTypes'
import codeLanguages from '@/constants/codeLanguages'
import Button from '@/components/Button'
import Input from '@/components/Input'
import MarkdownEditor from '@/components/MarkdownEditor'
import WidgetSelector from '../WidgetSelector'
import {
  addPageContent,
  updatePageContent,
  updateCustomModuleVersion,
  setActivityWizardModalOpen,
} from '@/store/customModules/actions'
import { Container } from '../styles'

const AIValidatedActivityForm = ({ activityTypeName, icon }) => {
  const dispatch = useDispatch()

  const { currentCustomModuleVersion, mode, isActivityWizardModalOpen, isCustomModulesLoading } = useSelector(
    (state) => state.customModules,
  )
  const sectionId = isActivityWizardModalOpen?.sectionId
  const activity = isActivityWizardModalOpen?.activity
  const isEditing = !!activity?.id

  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [widgetType, setWidgetType] = useState(activityWidgetTypes[0].code)
  const [widgetCodeLanguage, setWidgetCodeLanguage] = useState(codeLanguages[0].value)
  const [widgetRichTextEnabled, setWidgetRichTextEnabled] = useState(false)
  const [solution, setSolution] = useState('')
  const [hint, setHint] = useState('')

  const [form] = Form.useForm()

  const handleSaveActivity = async () => {
    try {
      await form.validateFields()
    } catch (error) {
      showToast('Please fill all required fields.', 'error')
      return
    }

    let activityBlock = {
      id: isEditing ? activity?.id : uuidv4(),
      type: 'ai_validated',
      title_md: title,
      content_md: content,
      //
      widget_type: widgetType,
      widget_metadata: {
        ...(widgetType === 'code' ? { code_language: widgetCodeLanguage } : {}),
        ...(widgetType === 'text' ? { rich_text_enabled: widgetRichTextEnabled } : {}),
      },
      //
      expected_outcome_md: '',
      solution_md: solution,
      hint_md: hint,
    }

    let updatedContent
    if (isEditing) {
      updatedContent = await dispatch(updatePageContent(sectionId, activityBlock))
      showToast('Activity updated successfully!')
    } else {
      updatedContent = await dispatch(addPageContent(sectionId, activityBlock))
      showToast('Activity created successfully!')
    }

    if (mode === 'edit') {
      dispatch(
        updateCustomModuleVersion(
          currentCustomModuleVersion?.custom_module?.id,
          currentCustomModuleVersion?.id,
          { content_json: updatedContent },
          () => {},
          (err) => showToast(err, 'error'),
        ),
      )
    }

    dispatch(setActivityWizardModalOpen(false))
  }

  useEffect(() => {
    if (!activity) return

    setTitle(activity?.title_md)
    setContent(activity?.content_md)
    setWidgetType(activity?.widget_type)
    setWidgetCodeLanguage(activity?.widget_metadata?.code_language)
    setWidgetRichTextEnabled(activity?.widget_metadata?.rich_text_enabled)
    setSolution(activity?.solution_md)
    setHint(activity?.hint_md)

    form.setFieldsValue({
      Title: activity?.title_md,
      'Solution / Correct answer': activity?.solution_md,
    })
  }, [activity])

  return (
    <Container className="ai-graded-activity-form">
      <div className="header">
        <div className="icon-container rounded">{icon}</div>
        <h4 className="title">
          {isEditing ? 'Editing' : ''} {activityTypeName} activity
        </h4>
      </div>

      <Form className="activity-form" form={form} name="activity-form">
        <div className="activity-form-container">
          <Form.Item name="Title" initialValue={activity?.title_md} rules={[{ required: true }]}>
            <Input
              className="activity-title"
              label="Activity title"
              value={title}
              placeholder="Activity title"
              size="large"
              onChange={(evt) => setTitle(evt.target.value)}
            />
          </Form.Item>

          <MarkdownEditor
            label="Objective"
            content={content}
            placeholder="Write your question objective"
            info={
              <>
                This will be used by the AI agent to evaluate your students' answer. A clear and concise objective is
                key to ensure a good performance of the AI without making mistakes.
                <br />
                <br />
                Make sure you specify all the edge cases that can throw off either your student or the AI.
              </>
            }
            onChange={setContent}
          />

          <Form.Item name="Solution / Correct answer" initialValue={activity?.solution} rules={[{ required: true }]}>
            <MarkdownEditor
              label="Solution / Correct answer"
              content={solution}
              placeholder="Write the solution / correct answer"
              info="The correct answer will be fed to the AI in order to know what's the expected/desired answer. It will be used to compare against your student's answer."
              onChange={setSolution}
            />
          </Form.Item>

          <hr />

          <WidgetSelector
            title={'Choose a widget to be shown by the student:'}
            widgetType={widgetType}
            setWidgetType={setWidgetType}
            widgetCodeLanguage={widgetCodeLanguage}
            setWidgetCodeLanguage={setWidgetCodeLanguage}
            widgetRichTextEnabled={widgetRichTextEnabled}
            setWidgetRichTextEnabled={setWidgetRichTextEnabled}
          />

          <hr />

          <Collapse
            className="advanced-options-collapse"
            ghost
            expandIconPosition="end"
            items={[
              {
                key: '1',
                label: 'Show advanced options',
                children: (
                  <div className="advanced-options-container">
                    <MarkdownEditor label="Hints" content={hint} placeholder="Hints" onChange={setHint} />
                  </div>
                ),
              },
            ]}
          />
        </div>

        <div className="actions">
          <Button type="default" onClick={() => dispatch(setActivityWizardModalOpen(false))}>
            Cancel
          </Button>

          <Button type="primary" onClick={handleSaveActivity} loading={isCustomModulesLoading}>
            {isEditing ? 'Update' : 'Create'}
          </Button>
        </div>
      </Form>
    </Container>
  )
}

export default AIValidatedActivityForm
