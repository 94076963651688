import styled, { css } from 'styled-components'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.gray1};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 14px;
  border-radius: 10px;
  padding: 28px 30px;

  ${(props) =>
    props.$hasBorder &&
    css`
      border: 1px solid ${(props) => props.theme.colors.gray4};
    `}

  > .stack-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    .stack-image {
      height: 28px;
    }

    .main-button {
      font-weight: 400;
      border-color: ${(props) => props.theme.colors.gray5};
      border-radius: 6px !important;
      padding: 10px;

      .icon {
        color: ${(props) => props.theme.colors.gray6};
        transition: 0.3s all;
      }

      &:hover {
        .icon {
          color: ${(props) => props.theme.colors.geekblue4};
        }
      }
    }
  }

  .title-container {
    max-height: 90px;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .title {
      margin: 0px;
    }

    .description p {
      color: ${(props) => props.theme.colors.gray7};
      font-size: 12px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      margin: 0px;
    }
  }
`
