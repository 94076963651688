import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentSubscription: null,
  isLoading: false,
  error: null,
}

const slice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    SET_SUBSCRIPTIONS_LOADING: (state, { payload }) => {
      state.isLoading = payload
    },
    SUBSCRIPTION_UPDATED: (state, { payload }) => {
      state.currentSubscription = payload
    },
    SET_SUBSCRIPTIONS_ERROR: (state, { payload }) => {
      state.error = payload
    },
    RESET_SUBSCRIPTIONS_STATE: () => initialState,
  },
})

export const { SET_SUBSCRIPTIONS_LOADING, SUBSCRIPTION_UPDATED, SET_SUBSCRIPTIONS_ERROR, RESET_SUBSCRIPTIONS_STATE } =
  slice.actions
export default slice.reducer
