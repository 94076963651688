import styled, { css } from 'styled-components'
import { Modal as AntForm } from 'antd'

export const Modal = styled(AntForm)`
  top: 80px;

  ${(props) =>
    props.$activityType
      ? css`
          max-width: 650px;

          .ant-modal-content {
            background: linear-gradient(
              180deg,
              ${(props) => props.theme.colors.geekblue1} 0%,
              ${(props) => props.theme.colors.gray1} 100%
            );
          }
        `
      : css`
          max-width: 860px;

          .ant-modal-content {
            background: ${(props) => props.theme.colors.gray4} !important;
          }

          .ant-modal-body > .content {
            padding-bottom: 28px;
          }
        `}

  .ant-modal-content {
    padding: 36px;

    ${(props) =>
      props.$activityType === 'ai_validated' &&
      css`
        background:
          linear-gradient(163deg, rgba(255, 255, 255, 0) 17.46%, #fff 73.93%),
          radial-gradient(
            217.52% 153.59% at 122.4% 118.47%,
            rgba(145, 150, 255, 0.3) 47.5%,
            rgba(172, 143, 255, 0.2) 73%,
            rgba(158, 180, 254, 0.2) 94.27%
          ),
          linear-gradient(180deg, #f6f9ff 0%, #fff 100%);
      `}
  }

  .ant-modal-body {
    > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 36px;

      .type-selector-title {
        font-weight: 700;
        text-align: center;
        margin: 20px 0px 0px;
      }

      .title {
        margin: 0px;
      }

      .activity-types-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 24px;

        .activity-content {
          height: 180px;
          width: 200px;
          background-color: ${(props) => props.theme.colors.gray1};
          color: ${(props) => props.theme.colors.gray1};
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 30px 20px;
          user-select: none;
          cursor: pointer;
          transition: all 0.3s;

          &:hover {
            background-color: ${(props) => props.theme.colors.geekblue2};
          }

          .activity-type-icon {
            color: ${(props) => props.theme.colors.geekblue5};
          }

          .activity-name {
            color: ${(props) => props.theme.colors.gray11};
            margin: 0px;
          }

          .activity-description {
            color: ${(props) => props.theme.colors.gray7};
            margin: 0px;
          }

          &.is-highlighted {
            background:
              linear-gradient(143deg, rgba(255, 255, 255, 0) 7.22%, #fff 81.64%),
              radial-gradient(
                217.52% 153.59% at 122.4% 118.47%,
                rgba(145, 150, 255, 0.71) 47.5%,
                rgba(172, 143, 255, 0.71) 73%,
                rgba(158, 180, 254, 0.71) 94.27%
              ),
              #fff;
            color: ${(props) => props.theme.colors.geekblue5};

            .activity-type-icon {
              color: ${(props) => props.theme.colors.gray1};
            }

            &:hover {
              background-color: ${(props) => props.theme.colors.geekblue4};
            }
          }

          &.is-disabled {
            background: ${(props) => props.theme.colors.gray5};
            border: 1px solid ${(props) => props.theme.colors.gray5};
            cursor: not-allowed;

            .activity-name {
              opacity: 0.7;
            }

            .activity-type-icon {
              color: ${(props) => props.theme.colors.geekblue5};
              opacity: 0.7;
            }

            &:hover {
              background-color: ${(props) => props.theme.colors.gray5};
            }
          }
        }
      }
    }
  }
`
