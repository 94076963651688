import dayjs from 'dayjs'
import {
  CUSTOM_MODULE_UPDATED,
  CUSTOM_MODULE_VERSION_UPDATED,
  CONTENT_JSON_UPDATED,
  SET_CONTENT_JSON_DIRTY,
  CUSTOM_MODULE_MODE_UPDATED,
  INFO_SIDE_DRAWER_UPDATED,
  SET_ACTIVITY_WIZARD_MODAL_OPEN,
  SET_PUBLISH_MODAL_OPEN,
  SET_CUSTOM_MODULES_LOADING,
  SET_CUSTOM_MODULES_PUBLISH_LOADING,
  SET_CUSTOM_MODULES_ERROR,
  RESET_CUSTOM_MODULES_STATE,
} from '@/store/customModules'
import { LAB_UPDATED } from '@/store/labs'
import {
  getCustomModule,
  getCustomModuleVersion,
  patchCustomModuleVersion,
  postCustomModuleVersion,
  postCustomModuleVersionEdit,
  postCustomModuleVersionStop,
  postCustomModuleVersionPublish,
} from '@/services/customModules'

const fetchCustomModule = (customModuleId) => async (dispatch) => {
  try {
    dispatch(SET_CUSTOM_MODULES_LOADING(true))

    const customModule = await getCustomModule(customModuleId)
    dispatch(CUSTOM_MODULE_UPDATED(customModule))
  } catch (error) {
    const { message } = error
    dispatch(SET_CUSTOM_MODULES_ERROR(message))
  } finally {
    dispatch(SET_CUSTOM_MODULES_LOADING(false))
  }
}

const fetchCustomModuleVersion = (customModuleId, versionId) => async (dispatch) => {
  try {
    dispatch(SET_CUSTOM_MODULES_LOADING(true))

    const customModuleVersion = await getCustomModuleVersion(customModuleId, versionId)
    dispatch(CUSTOM_MODULE_VERSION_UPDATED(customModuleVersion))
  } catch (error) {
    const { message } = error
    dispatch(SET_CUSTOM_MODULES_ERROR(message))
  } finally {
    dispatch(SET_CUSTOM_MODULES_LOADING(false))
  }
}

const updateCustomModuleVersion = (customModuleId, versionId, data, onSuccess, onError) => async (dispatch) => {
  try {
    dispatch(SET_CUSTOM_MODULES_LOADING(true))

    let auxData = { ...data }
    let auxContentJson = null
    if (auxData?.content_json) {
      auxContentJson = auxData.content_json

      // remove grading from content_json to avoid API error
      auxData.content_json = auxData.content_json.map((page) => {
        const auxPage = { ...page }
        auxPage.content = auxPage.content.map((content) => {
          const auxContent = { ...content }
          delete auxContent.grading
          return auxContent
        })
        return auxPage
      })
    }

    let customModuleVersion = await patchCustomModuleVersion(customModuleId, versionId, auxData)

    if (auxContentJson) {
      // add grading back to content_json
      customModuleVersion = {
        ...customModuleVersion,
        content_json: customModuleVersion.content_json.map((page, pageIndex) => {
          const auxPage = { ...page }
          auxPage.content = auxPage.content.map((content, contentIndex) => {
            const auxContent = { ...content }

            if (auxContentJson?.[pageIndex]?.content?.[contentIndex]?.grading) {
              auxContent.grading = auxContentJson[pageIndex].content[contentIndex].grading
            }

            return auxContent
          })
          return auxPage
        }),
      }
    }
    dispatch(CUSTOM_MODULE_VERSION_UPDATED(customModuleVersion))
    dispatch(SET_CONTENT_JSON_DIRTY(false))

    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    const errorMessage = error?.message || error?.detail

    if (onError) {
      onError(errorMessage)
    }
  } finally {
    dispatch(SET_CUSTOM_MODULES_LOADING(false))
  }
}

const createCustomModuleVersion = (customModuleId, onSuccess, onError) => async (dispatch, getState) => {
  try {
    dispatch(SET_CUSTOM_MODULES_LOADING(true))

    const { currentCustomModule } = getState().customModules

    const customModuleVersion = await postCustomModuleVersion(customModuleId)
    const auxCustomModuleVersion = {
      ...customModuleVersion,
      justCreated: true,
    }

    dispatch(CUSTOM_MODULE_VERSION_UPDATED(auxCustomModuleVersion))

    const auxCustomModule = {
      ...currentCustomModule,
      versions: [...currentCustomModule.versions, auxCustomModuleVersion],
    }

    dispatch(CUSTOM_MODULE_UPDATED(auxCustomModule))

    if (onSuccess) {
      onSuccess(customModuleVersion)
    }
  } catch (error) {
    const { message } = error

    if (onError) {
      onError()
    }
  } finally {
    dispatch(SET_CUSTOM_MODULES_LOADING(false))
  }
}

const createCustomModuleVersionEdit = (customModuleId, versionId, onSuccess, onError) => async (dispatch, getState) => {
  try {
    dispatch(SET_CUSTOM_MODULES_LOADING(true))

    const { userProfile } = getState().users
    const { currentCustomModuleVersion } = getState().customModules
    const { currentLab } = getState().labs

    const response = await postCustomModuleVersionEdit(customModuleId, versionId)
    const newAllocatedSession = response?.lab_session

    dispatch(
      CUSTOM_MODULE_VERSION_UPDATED({
        ...currentCustomModuleVersion,
        allocated_by: {
          id: userProfile.id,
          name: `${userProfile.first_name} ${userProfile.last_name}`,
        },
        allocated_session: {
          id: newAllocatedSession?.id,
          status: newAllocatedSession?.status,
        },
      }),
    )

    dispatch(
      LAB_UPDATED({
        ...currentLab,
        allocated_session: newAllocatedSession,
      }),
    )

    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    const { message } = error

    if (onError) {
      onError(message)
    }
  } finally {
    dispatch(SET_CUSTOM_MODULES_LOADING(false))
  }
}

const createCustomModuleVersionStop =
  (customModuleId, versionId, data, onSuccess, onError) => async (dispatch, getState) => {
    try {
      dispatch(SET_CUSTOM_MODULES_LOADING(true))

      const { currentCustomModuleVersion } = getState().customModules
      const { currentLab } = getState().labs

      await postCustomModuleVersionStop(customModuleId, versionId, data)

      dispatch(
        CUSTOM_MODULE_VERSION_UPDATED({
          ...currentCustomModuleVersion,
          allocated_by: null,
          allocated_session: null,
        }),
      )

      dispatch(
        LAB_UPDATED({
          ...currentLab,
          last_deallocated_session: {
            ...currentLab?.allocated_session,
            status: 'deallocated',
            // deallocate_reason: deallocateReason,
          },
          allocated_session: {
            ...currentLab?.allocated_session,
            status: 'deallocated',
            // deallocate_reason: deallocateReason,
            devices: currentLab?.allocated_session?.devices?.map((d) => ({ ...d, status: 'stopping' })),
          },
          last_commit: currentLab?.commit_user_image
            ? {
                created: dayjs().toISOString(),
              }
            : null,
        }),
      )

      if (onSuccess) {
        onSuccess()
      }
    } catch (error) {
      const { message } = error

      if (onError) {
        onError(message)
      }
    } finally {
      dispatch(SET_CUSTOM_MODULES_LOADING(false))
    }
  }

const createCustomModuleVersionPublish =
  (customModuleId, versionId, onSuccess, onError) => async (dispatch, getState) => {
    try {
      dispatch(SET_CUSTOM_MODULES_PUBLISH_LOADING(true))

      const { currentCustomModule, currentCustomModuleVersion } = getState().customModules

      await postCustomModuleVersionPublish(customModuleId, versionId)

      const auxCustomModuleVersion = {
        ...currentCustomModuleVersion,
        status: 'published',
        allocated_by: null,
        allocated_session: null,
      }

      dispatch(CUSTOM_MODULE_VERSION_UPDATED(auxCustomModuleVersion))

      const auxCustomModule = {
        ...currentCustomModule,
        versions: currentCustomModule.versions.map((version) => {
          if (version.id === versionId) {
            return auxCustomModuleVersion
          }

          return version
        }),
      }

      dispatch(CUSTOM_MODULE_UPDATED(auxCustomModule))

      if (onSuccess) {
        onSuccess()
      }
    } catch (error) {
      const { message } = error

      if (onError) {
        onError(message)
      }
    } finally {
      dispatch(SET_CUSTOM_MODULES_PUBLISH_LOADING(false))
    }
  }

const addPage = (page) => (dispatch, getState) => {
  const { contentJson } = getState().customModules

  const updatedContent = [...(contentJson || []), page]
  dispatch(CONTENT_JSON_UPDATED(updatedContent))
  dispatch(SET_CONTENT_JSON_DIRTY(true))
}

const removePage = (pageId) => (dispatch, getState) => {
  const { contentJson } = getState().customModules

  const updatedContent = contentJson.filter((p) => p.id !== pageId)
  dispatch(CONTENT_JSON_UPDATED(updatedContent))
  dispatch(SET_CONTENT_JSON_DIRTY(true))
}

const updatePage = (pageId, updates) => (dispatch, getState) => {
  const { contentJson } = getState().customModules

  const updatedContent = contentJson.map((page) => {
    if (page.id === pageId) {
      return { ...page, ...updates }
    }

    return page
  })

  dispatch(CONTENT_JSON_UPDATED(updatedContent))
  dispatch(SET_CONTENT_JSON_DIRTY(true))
}

const updatePages = (pages) => (dispatch) => {
  dispatch(CONTENT_JSON_UPDATED(pages))
  dispatch(SET_CONTENT_JSON_DIRTY(true))
}

const addPageContent = (pageId, newContent) => (dispatch, getState) => {
  const { contentJson } = getState().customModules

  const newContentJson = contentJson.map((page) => {
    if (page.id === pageId) {
      return {
        ...page,
        content: [...page.content, newContent],
      }
    }

    return page
  })

  dispatch(CONTENT_JSON_UPDATED(newContentJson))
  dispatch(SET_CONTENT_JSON_DIRTY(true))
  return newContentJson
}

const removePageContent = (pageId, contentId) => (dispatch, getState) => {
  const { contentJson } = getState().customModules

  const updatedContent = contentJson.map((page) => {
    if (page.id === pageId) {
      return {
        ...page,
        content: page.content.filter((content) => content.id !== contentId),
      }
    }

    return page
  })

  dispatch(CONTENT_JSON_UPDATED(updatedContent))
  dispatch(SET_CONTENT_JSON_DIRTY(true))
}

const updatePageContent = (pageId, updatedContent) => (dispatch, getState) => {
  const { contentJson } = getState().customModules

  const newContentJson = contentJson.map((page) => {
    if (page.id === pageId) {
      return {
        ...page,
        content: page.content.map((content) => {
          if (content.id === updatedContent.id) {
            return updatedContent
          }

          return content
        }),
      }
    }

    return page
  })

  dispatch(CONTENT_JSON_UPDATED(newContentJson))
  dispatch(SET_CONTENT_JSON_DIRTY(true))
  return newContentJson
}

const setCustomModuleContentJson = (contentJson) => (dispatch) => {
  dispatch(CONTENT_JSON_UPDATED(contentJson))
  dispatch(SET_CONTENT_JSON_DIRTY(false))
}

const setCustomModuleMode = (mode) => (dispatch) => {
  dispatch(CUSTOM_MODULE_MODE_UPDATED(mode))
}

const selectInfoSideDrawerItem = (newPage) => (dispatch) => {
  dispatch(INFO_SIDE_DRAWER_UPDATED(newPage))
}

const setActivityWizardModalOpen = (isOpen) => (dispatch) => {
  dispatch(SET_ACTIVITY_WIZARD_MODAL_OPEN(isOpen))
}

const setPublishModalOpen = (isOpen) => (dispatch) => {
  dispatch(SET_PUBLISH_MODAL_OPEN(isOpen))
}

const resetCustomModulesState = () => (dispatch) => {
  dispatch(RESET_CUSTOM_MODULES_STATE())
}

export {
  fetchCustomModule,
  fetchCustomModuleVersion,
  updateCustomModuleVersion,
  createCustomModuleVersion,
  createCustomModuleVersionEdit,
  createCustomModuleVersionStop,
  createCustomModuleVersionPublish,
  addPage,
  removePage,
  updatePage,
  updatePages,
  addPageContent,
  removePageContent,
  updatePageContent,
  setCustomModuleContentJson,
  setCustomModuleMode,
  selectInfoSideDrawerItem,
  setActivityWizardModalOpen,
  setPublishModalOpen,
  resetCustomModulesState,
}
