import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Tooltip, Radio, Skeleton } from 'antd'
import { Warning24Regular, ArrowSquareDown24Regular, Megaphone24Regular } from '@fluentui/react-icons'
import { showToast } from '@/utils/toast'
import { isDataWarsHostName } from '@/helpers/env'
import Button from '@/components/Button'
import HTMLBlock from '@/components/HTMLBlock'
import ProTooltip from '@/components/ProTooltip'
import PublishImage from '@/assets/images/playgrounds/publish.png'
import { togglePublishModal, setSharingModalOpen, createPlaygroundPublish } from '@/store/playgrounds/actions'
import { Modal } from './styles'

const PublishModal = () => {
  const dispatch = useDispatch()

  const {
    currentPlayground: playground,
    playgroundPreview,
    isPublishModalOpen,
    isPlaygroundPreviewLoading,
    isPlaygroundPublishLoading,
    isLoading,
  } = useSelector((state) => state.playgrounds)
  const { currentSubscription } = useSelector((state) => state.subscriptions)

  const canPublishUnlistedPlayground = currentSubscription?.available_features?.playground_unlisted?.available

  const isCustomProject = !!playground?.metadata?.account_id
  const isMemberCopy = playground?.metadata?.is_student_copy

  const hasCopies = !!playground?.copies?.length
  const allowPublishAfterCopied = playground?.config?.allow_publish_after_copied
  const enablePublish = !hasCopies || allowPublishAfterCopied

  const [visibility, setVisibility] = useState('public')

  const onVisibilityChange = (e) => {
    setVisibility(e.target.value)
  }

  const handlePublish = async () => {
    if (isCustomProject && !enablePublish) return

    let auxVisibility = visibility
    if (isCustomProject) auxVisibility = 'unlisted'
    if (isMemberCopy) auxVisibility = 'private'

    const newVersion = await dispatch(createPlaygroundPublish(playground?.id, { visibility: auxVisibility }))

    if (newVersion && isCustomProject) {
      showToast(isMemberCopy ? 'Project was successfully submitted' : 'Custom project was successfully published')
    }

    if (newVersion && !isCustomProject) {
      showToast('Playground was successfully published')
      dispatch(setSharingModalOpen(true))
    }

    dispatch(togglePublishModal(false))
  }

  const renderPreviewContent = () => {
    if (isPlaygroundPreviewLoading) {
      return (
        <div className="module-preview-container">
          <div className="module-preview loading-content">
            <h4 className="title">Generating preview...</h4>
            <p className="description">This could take a few seconds...</p>

            <Skeleton active title={false} paragraph={{ rows: 4 }} />
            <Skeleton active title={false} paragraph={{ rows: 6 }} />
            <Skeleton active title={false} paragraph={{ rows: 2 }} />
          </div>
        </div>
      )
    }

    if (playgroundPreview) {
      return <HTMLBlock className="playground-preview" content={playgroundPreview} />
    }
  }

  return (
    <Modal
      className="playground-publish-modal"
      open={isPublishModalOpen}
      onCancel={() => {
        dispatch(togglePublishModal(false))
      }}
      keyboard={false}
      footer={null}
      $isCustomProject={isCustomProject}
    >
      <div className="content">
        {isCustomProject ? (
          <>
            <div className="header-container">
              {isMemberCopy ? (
                <p className="pre-title">
                  <Megaphone24Regular className="icon" /> <span className="bold">Create submission</span>
                </p>
              ) : (
                <p className="pre-title">
                  <Megaphone24Regular className="icon" /> <span className="bold">Ready to publish?</span>{' '}
                  {isDataWarsHostName && 'Make this custom project available to all your account members'}
                </p>
              )}

              <h1 className="playground-name">{playground?.name}</h1>

              <div className="actions">
                {isMemberCopy ? (
                  <div className="info-message">Creating a submission will notify your account admins.</div>
                ) : (
                  !allowPublishAfterCopied && (
                    <div className="info-message gold">
                      <Warning24Regular className="icon" />
                      {hasCopies
                        ? "Can't publish a new version of this project as it's currently in use by your account members."
                        : 'You can publish new versions as long as none of your users have started working on it.'}
                    </div>
                  )
                )}

                <Button
                  type="secondary"
                  icon={<ArrowSquareDown24Regular className="publish-icon" />}
                  loading={isLoading || isPlaygroundPublishLoading || isPlaygroundPreviewLoading}
                  onClick={handlePublish}
                  disabled={isCustomProject && !enablePublish}
                >
                  {isMemberCopy
                    ? playground?.versions?.length
                      ? 'Create new submission'
                      : 'Create submission'
                    : playground?.versions?.length
                      ? 'Publish new version'
                      : 'Publish'}
                </Button>
              </div>
            </div>

            <div className="preview-container">{renderPreviewContent()}</div>
          </>
        ) : (
          <>
            <div className="header-container">
              <img className="header-image" src={PublishImage} alt="Ready to publish?" />
              <h3 className="title">Ready to publish?</h3>
              <p className="info-text">Publish this playground to make it accesible to the world</p>
            </div>

            <div className="version-info">
              <div className="version-item">
                <h5 className="version-code">{`v${playground?.versions?.length + 1}`}</h5>

                <h5 className="playground-name">{playground?.name}</h5>
              </div>
            </div>

            <div className="visibility-box">
              <p className="title">Visibility options</p>

              <Radio.Group onChange={onVisibilityChange} value={visibility} disabled={isLoading}>
                <Tooltip
                  title={
                    <span>
                      <b>Public</b>
                      <br />

                      <span>Anyone at Datawars can find and view this Playground.</span>
                    </span>
                  }
                >
                  <Radio value={'public'}>Public</Radio>
                </Tooltip>

                {canPublishUnlistedPlayground ? (
                  <Tooltip
                    title={
                      <span>
                        <b>Unlisted</b>
                        <br />

                        <span>This Playground will only be visible to those that you share the link with.</span>
                      </span>
                    }
                  >
                    <Radio value={'unlisted'}>Unlisted</Radio>
                  </Tooltip>
                ) : (
                  <ProTooltip
                    title="Unlisted"
                    content={
                      'This Playground will only be visible to those that you share the link with. Only PRO users can select this option.'
                    }
                    showUpgradeButton
                    upgradeButtonText={'Upgrade to PRO'}
                  >
                    <Radio value={'unlisted'} disabled={!canPublishUnlistedPlayground}>
                      Unlisted
                    </Radio>
                  </ProTooltip>
                )}
              </Radio.Group>
            </div>

            <div className="footer">
              <Button
                type="secondary"
                size="large"
                loading={isLoading || !!isPlaygroundPublishLoading}
                disabled={isLoading}
                onClick={handlePublish}
              >
                Publish
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default PublishModal
