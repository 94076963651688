import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import invariant from 'tiny-invariant'
import { Modal, Checkbox, Radio, Tooltip, Button as AntdButton } from 'antd'
import { BulbOutlined, EyeOutlined, ExclamationCircleFilled, CodeOutlined } from '@ant-design/icons'
import {
  Delete24Regular,
  Edit24Regular,
  CheckmarkCircle24Filled,
  DismissCircle24Filled,
  Code24Regular,
  Sparkle24Filled,
  ArrowShuffle24Regular,
  ArrowShuffleOff24Regular,
} from '@fluentui/react-icons'
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import { attachClosestEdge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { DragHandleButton } from '@atlaskit/pragmatic-drag-and-drop-react-accessibility/drag-handle-button'
import activityTypes from '@/constants/activityTypes'
import Input from '@/components/Input'
import Button from '@/components/Button'
import CodeEditor from '@/components/CodeEditor'
import MarkdownEditor from '@/components/MarkdownEditor'
import ActivityWidget from '@/components/ActivityWidget'
import DropIndicator from '../DropIndicator'
import { createActivityCheck } from '@/store/activities/actions'
import { updatePageContent, removePageContent, setActivityWizardModalOpen } from '@/store/customModules/actions'
import DefaultDeviceIcon from '@/assets/images/device/default-icon.png'
import { Container } from './styles'

const SectionContent = ({ sectionId, content, canManageContent, readOnly, isDraggable }) => {
  const dispatch = useDispatch()
  const contentRef = useRef(null)

  const { isLoading: isActivityLoading } = useSelector((state) => state.activities)
  const { items: codeSnippetsData } = useSelector((state) => state.codeSnippets)
  const { currentLab } = useSelector((state) => state.labs)
  const { currentCustomModuleVersion, contentJson, mode, isCustomModulesLoading } = useSelector(
    (state) => state.customModules,
  )
  const allActivities = contentJson
    ?.map((page) => page?.content)
    ?.flat()
    ?.filter((item) => item.type !== 'markdown_block')
  const activityIndex = allActivities?.findIndex((item) => item.id === content.id)

  const [answer, setAnswer] = useState()
  const [infoBlock, setInfoBlock] = useState()
  const [isDragging, setIsDragging] = useState(false)
  const [closestEdge, setClosestEdge] = useState(null)

  const isActivity = activityTypes?.map((a) => a.type).includes(content.type)
  const isLabRunning =
    currentCustomModuleVersion?.allocated_session?.status === 'allocated' &&
    currentLab?.id === currentCustomModuleVersion?.lab_id
  const codeSnippetData = content?.snippet_id && codeSnippetsData?.find((snippet) => snippet.id === content.snippet_id)

  const handleSetInfoBlock = (block) => {
    if (infoBlock === block) {
      setInfoBlock(null)
      return
    }

    setInfoBlock(block)
  }

  const renderContent = () => {
    if (content.type === 'markdown_block') {
      return <MarkdownEditor content={content.content_md} onChange={handleMarkdownBlockChange} readOnly={readOnly} />
    }

    if (activityTypes?.map((a) => a.type).includes(content?.type)) {
      return (
        <div className="activity-preview-container">
          <div className="activity-body">
            <span className="activity-number">{activityIndex + 1}</span>

            <div className="title-container">
              <h5 className="title">{content.title_md || 'Activity title'}</h5>
            </div>

            <div className="activity-actions">
              {content?.snippet_id && codeSnippetData && (
                <Tooltip title={`Template: ${codeSnippetData?.name}`}>
                  <AntdButton className="snippet-btn" shape="circle" icon={<Code24Regular />} />
                </Tooltip>
              )}

              {content?.instructor_code && (
                <Tooltip title="View instructor/validation code">
                  <AntdButton
                    className={`instructor-code-btn ${infoBlock === 'instructor-code' && 'is-selected'}`}
                    shape="circle"
                    icon={<CodeOutlined />}
                    onClick={() => handleSetInfoBlock('instructor-code')}
                  />
                </Tooltip>
              )}

              <Tooltip
                title={content?.hint_md ? (infoBlock === 'hint' ? 'Hide hint' : 'View hint') : 'No hint available'}
              >
                <AntdButton
                  className={`hint-btn ${infoBlock === 'hint' && 'is-selected'}`}
                  shape="circle"
                  icon={<BulbOutlined />}
                  onClick={() => handleSetInfoBlock('hint')}
                  disabled={!content?.hint_md}
                />
              </Tooltip>

              <Tooltip
                title={
                  content?.solution_md
                    ? infoBlock === 'solution'
                      ? 'Hide solution'
                      : 'View solution'
                    : 'No solution available'
                }
              >
                <AntdButton
                  className={`solution-btn ${infoBlock === 'solution' ? 'is-selected' : ''}`}
                  shape="circle"
                  icon={<EyeOutlined />}
                  onClick={() => handleSetInfoBlock('solution')}
                  disabled={!content?.solution_md}
                />
              </Tooltip>
            </div>
          </div>

          {content?.content_md && (
            <MarkdownEditor className="activity-content-markdown" content={content.content_md} readOnly />
          )}
        </div>
      )
    }
  }

  const handleCheckActivity = () => {
    const body = {
      activityId: content.id,
      // input
      ...(content?.type === 'input' ? { textAnswer: answer } : {}),

      // multiple_choice
      ...(content?.type === 'multiple_choice' ? { selectedAnswer: Array.isArray(answer) ? answer : [answer] } : {}),

      // code_validated
      ...(content?.type === 'code_validated' ? { submittedAnswer: answer } : {}),

      // ai_validated, latex_validated, manually_validated
      ...(content?.type === 'ai_validated' ? { textAnswer: answer } : {}),
      ...(content?.type === 'latex_validated' ? { textAnswer: answer } : {}),
      ...(content?.type === 'manually_validated' ? { textAnswer: answer } : {}),
    }

    dispatch(createActivityCheck(body))
  }

  const isActivityDisabled = () => {
    if (content?.type === 'code_validated') {
      if (!isLabRunning || (content?.code_input && !answer)) {
        return true
      }

      return false
    }

    if (content?.type === 'manually_validated') {
      if (content?.input_required && !answer) {
        return true
      }

      return false
    }

    // for all other types
    if (!answer) {
      return true
    }

    return false
  }

  const renderSubmitContainer = () => {
    const auxAnswers = content?.answers && [...content?.answers]
    const options =
      content?.type === 'multiple_choice' &&
      auxAnswers
        ?.sort((x, y) => x.stick_to_bottom - y.stick_to_bottom)
        ?.map((a) => ({
          label: <MarkdownEditor content={a.content_md} readOnly />,
          value: a.id,
        }))

    return (
      <div className="submit-container">
        <div className="input-content">
          {content?.type === 'input' && (
            <Input
              className="activity-input"
              label="Your answer"
              value={answer}
              placeholder="Enter your answer"
              size="large"
              onChange={(evt) => setAnswer(evt.target.value)}
              disabled={isActivityLoading && isActivityLoading === content?.id}
            />
          )}

          {content?.type === 'multiple_choice' && (
            <>
              {content?.widget === 'checkbox' ? (
                <Checkbox.Group
                  className="activity-answers-group"
                  options={options}
                  value={answer}
                  onChange={(answer) => setAnswer(answer)}
                  disabled={isActivityLoading && isActivityLoading === content?.id}
                />
              ) : (
                <Radio.Group
                  className="activity-answers-group"
                  options={options}
                  value={answer}
                  onChange={(evt) => setAnswer(evt.target.value)}
                  disabled={isActivityLoading && isActivityLoading === content?.id}
                />
              )}
            </>
          )}

          {content?.type === 'code_validated' && content?.code_input?.language && (
            <CodeEditor
              className="activity-code-editor"
              language={content?.code_input?.language}
              placeholder={content?.code_input?.placeholder}
              value={answer}
              setValue={setAnswer}
              readOnly={isActivityLoading && isActivityLoading === content?.id}
            />
          )}

          {(content?.type === 'ai_validated' ||
            (content?.type === 'manually_validated' && content?.input_required)) && (
            <ActivityWidget
              widgetType={content?.widget_type}
              widgetMetadata={content?.widget_metadata}
              value={answer}
              onChange={setAnswer}
              disabled={isActivityLoading && isActivityLoading === content?.id}
            />
          )}

          {content?.type === 'latex_validated' && (
            <Input
              type={'mathlive'}
              label="Your answer"
              placeholder="Enter \ your \ answer \ (e.g., \ f(x) = 2 + x)"
              value={answer}
              onChange={(evt) => setAnswer(evt.target.value)}
            />
          )}
        </div>

        <div className="actions-content">
          <div className="submit-content">
            <Tooltip
              title={
                content?.type === 'code_validated' && (!isLabRunning || mode !== 'edit')
                  ? 'You can only check code validated activities while Editing your project'
                  : content?.type !== 'code_validated' && !answer
                    ? 'Enter your answer first'
                    : ''
              }
            >
              <Button
                type="primary"
                onClick={handleCheckActivity}
                loading={isCustomModulesLoading || (isActivityLoading && isActivityLoading === content?.id)}
                disabled={isActivityDisabled()}
              >
                {content?.type === 'manually_validated' ? 'Mark as done' : 'Check activity'}
              </Button>
            </Tooltip>

            {content?.grading && !(isActivityLoading && isActivityLoading === content?.id) && (
              <>
                {content?.grading?.is_passed === true && (
                  <span className="tag success">
                    <CheckmarkCircle24Filled className="icon" /> Correct
                  </span>
                )}

                {content?.grading?.is_passed === false && (
                  <span className="tag error">
                    <DismissCircle24Filled className="icon" /> Incorrect
                  </span>
                )}

                {content?.grading?.is_passed === null && <span className="tag info">Answer submitted</span>}
              </>
            )}
          </div>

          {content?.type === 'input' && (
            <>
              {content?.case_sensitive ? (
                <span className="tag default">Is case sensitive</span>
              ) : (
                <span className="tag ">Is not case sensitive</span>
              )}
            </>
          )}

          {content?.type === 'multiple_choice' && (
            <>
              {content?.shuffle_answers ? (
                <span className="tag default">
                  <ArrowShuffle24Regular className="icon" /> Shuffle
                </span>
              ) : (
                <span className="tag">
                  <ArrowShuffleOff24Regular className="icon" /> Shuffle
                </span>
              )}
            </>
          )}

          {content?.type === 'code_validated' && (
            <>
              {content?.device_name ? (
                <span className="tag default">
                  <img className="image" src={DefaultDeviceIcon} />
                  {content?.device_name}
                </span>
              ) : null}
            </>
          )}
        </div>
      </div>
    )
  }

  const handleMarkdownBlockChange = (newMarkdown) => {
    dispatch(updatePageContent(sectionId, { ...content, content_md: newMarkdown }))
  }

  const handleEditActivity = () => {
    dispatch(setActivityWizardModalOpen({ sectionId, activity: content }))
  }

  const handleRemoveContent = () => {
    Modal.confirm({
      title: 'Remove content',
      content: 'Are you sure you want to remove this content? This action cannot be undone.',
      icon: <ExclamationCircleFilled />,
      okText: 'Yes, remove',
      cancelText: 'Keep content',
      onOk: () => dispatch(removePageContent(sectionId, content.id)),
      okButtonProps: {
        danger: true,
      },
    })
  }

  const getTagContent = () => {
    const activityType = activityTypes.find((t) => t.type === content.type)

    if (activityType) return activityType.name

    if (content.type === 'markdown_block') {
      return 'Content'
    }

    return 'Content'
  }

  useEffect(() => {
    const contentEl = contentRef.current
    invariant(contentEl)

    return combine(
      draggable({
        element: contentEl,
        getInitialData: () => ({ type: 'CONTENT', id: content.id, sectionId }),
        canDrag: () => !readOnly,
        onDragStart: () => setIsDragging(true),
        onDrop: () => setIsDragging(false),
      }),
      dropTargetForElements({
        element: contentEl,
        canDrop: ({ source, self }) => !readOnly,
        getData: ({ input, element }) => {
          const data = { type: 'CONTENT', id: content.id, sectionId }

          return attachClosestEdge(data, {
            input,
            element,
            allowedEdges: ['top', 'bottom'],
          })
        },
        getIsSticky: () => true,
        onDragEnter: (args) => {
          if (args.source.data.type === 'CONTENT' && args.source.data.id !== content.id) {
            setClosestEdge(extractClosestEdge(args.self.data))
          }
        },
        onDrag: (args) => {
          if (args.source.data.type === 'CONTENT' && args.source.data.id !== content.id) {
            setClosestEdge(extractClosestEdge(args.self.data))
          }
        },
        onDragLeave: () => {
          setClosestEdge(null)
        },
        onDrop: () => {
          setClosestEdge(null)
        },
      }),
    )
  }, [content.id, readOnly])

  return (
    <Container ref={contentRef} className={`section-content ${isDragging ? 'is-dragging' : ''}`} $readOnly={readOnly}>
      <div className="options-header">
        <div className="options">
          {!readOnly && (
            <>
              {isDraggable && canManageContent && (
                <div className="icon-container">
                  <DragHandleButton className="drag-button" label="Reorder" />
                </div>
              )}

              {isActivity && (
                <div className="icon-container" onClick={() => handleEditActivity()}>
                  <Edit24Regular className="icon" />
                </div>
              )}

              {canManageContent && (
                <div className="icon-container" onClick={handleRemoveContent}>
                  <Delete24Regular className="icon" />
                </div>
              )}
            </>
          )}
        </div>

        <p className="tag">{getTagContent()}</p>
      </div>

      <div className="content-container">
        {content.type === 'ai_validated' && (
          <div className="ai-graded-tag">
            <Sparkle24Filled className="icon" /> AI graded activity
          </div>
        )}

        {renderContent()}

        {isActivity && renderSubmitContainer()}

        {content?.grading?.grade_output?.stdout && (
          <pre className="info-block error">{content?.grading?.grade_output?.stdout}</pre>
        )}

        {content?.grading?.grade_output?.stderr && (
          <pre className="info-block out">{content?.grading?.grade_output?.stderr}</pre>
        )}

        {infoBlock === 'instructor-code' && (
          <div className="info-block">
            <CodeEditor language={'python'} value={content.instructor_code} readOnly />
          </div>
        )}

        {infoBlock === 'hint' && (
          <div className="info-block">
            <MarkdownEditor className="activity-content-markdown" content={content.hint_md} readOnly />
          </div>
        )}

        {infoBlock === 'solution' && (
          <div className="info-block">
            <MarkdownEditor className="activity-content-markdown" content={content.solution_md} readOnly />
          </div>
        )}
      </div>

      {closestEdge && <DropIndicator edge={closestEdge} />}
    </Container>
  )
}

export default SectionContent
