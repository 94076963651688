const latexParserErrors = [
  {
    code: 'unknown-command',
    message: 'The LaTeX command is not recognized (e.g., \\badcommand).',
  },
  {
    code: 'invalid-command',
    message: 'A command is used incorrectly or in an invalid context.',
  },
  {
    code: 'unbalanced-braces',
    message: 'There are unmatched { or } in the expression.',
  },
  {
    code: 'unknown-environment',
    message: 'The environment (e.g., \\begin{something}) is not recognized.',
  },
  {
    code: 'unbalanced-environment',
    message: 'An environment (\\begin{...} and \\end{...}) is not properly closed.',
  },
  {
    code: 'unbalanced-mode-shift',
    message: 'A mode shift ($...$ or $$...$$) is unbalanced.',
  },
  {
    code: 'missing-argument',
    message: 'A required argument for a command is missing (e.g., \\frac{1+2}).',
  },
  {
    code: 'too-many-infix-commands',
    message: 'More than one infix command (like + or -) appears where only one is allowed.',
  },
  {
    code: 'unexpected-command-in-string',
    message: "A command was used inside a text string where it's not allowed.",
  },
  {
    code: 'missing-unit',
    message: 'A required unit (e.g., pt, cm) is missing in a measurement.',
  },
  {
    code: 'unexpected-delimiter',
    message: "A delimiter was found where it's not expected.",
  },
  {
    code: 'unexpected-token',
    message: 'An invalid token was found in the LaTeX expression.',
  },
  {
    code: 'unexpected-end-of-string',
    message: 'The LaTeX input ended unexpectedly, likely due to a missing closing element.',
  },
  {
    code: 'improper-alphabetic-constant',
    message: 'A numeric expression was improperly formatted as an alphabetic constant.',
  },
]

export default latexParserErrors
