import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { Collapse, Form } from 'antd'
import { showToast } from '@/utils/toast'
import Button from '@/components/Button'
import Input from '@/components/Input'
import MarkdownEditor from '@/components/MarkdownEditor'
import {
  addPageContent,
  updatePageContent,
  updateCustomModuleVersion,
  setActivityWizardModalOpen,
} from '@/store/customModules/actions'
import { Container } from '../styles'

const LatexValidatedActivityForm = ({ activityTypeName, icon }) => {
  const dispatch = useDispatch()

  const { currentCustomModuleVersion, mode, isActivityWizardModalOpen, isCustomModulesLoading } = useSelector(
    (state) => state.customModules,
  )
  const sectionId = isActivityWizardModalOpen?.sectionId
  const activity = isActivityWizardModalOpen?.activity
  const isEditing = !!activity?.id

  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [answer, setAnswer] = useState('')
  const [solution, setSolution] = useState('')
  const [hint, setHint] = useState('')

  const [form] = Form.useForm()

  const handleSaveActivity = async () => {
    try {
      await form.validateFields()
    } catch (error) {
      showToast('Please fill all required fields.', 'error')
      return
    }

    let activityBlock = {
      id: isEditing ? activity?.id : uuidv4(),
      type: 'latex_validated',
      title_md: title,
      content_md: content,
      //
      correct_answer: answer,
      //
      expected_outcome_md: '',
      solution_md: solution,
      hint_md: hint,
    }

    let updatedContent
    if (isEditing) {
      updatedContent = await dispatch(updatePageContent(sectionId, activityBlock))
      showToast('Activity updated successfully!')
    } else {
      updatedContent = await dispatch(addPageContent(sectionId, activityBlock))
      showToast('Activity created successfully!')
    }

    if (mode === 'edit') {
      dispatch(
        updateCustomModuleVersion(
          currentCustomModuleVersion?.custom_module?.id,
          currentCustomModuleVersion?.id,
          { content_json: updatedContent },
          () => {},
          (err) => showToast(err, 'error'),
        ),
      )
    }

    dispatch(setActivityWizardModalOpen(false))
  }

  useEffect(() => {
    if (!activity) return

    setTitle(activity?.title_md)
    setContent(activity?.content_md)
    setAnswer(activity?.correct_answer)
    setSolution(activity?.solution_md)
    setHint(activity?.hint_md)

    form.setFieldsValue({
      Title: activity?.title_md,
      'Correct answer': activity?.correct_answer,
    })
  }, [activity])

  return (
    <Container className="ai-graded-activity-form">
      <div className="header">
        <div className="icon-container">{icon}</div>
        <h4 className="title">
          {isEditing ? 'Editing' : ''} {activityTypeName} activity
        </h4>
      </div>

      <Form className="activity-form" form={form} name="activity-form">
        <div className="activity-form-container">
          <Form.Item name="Title" initialValue={activity?.title_md} rules={[{ required: true }]}>
            <Input
              className="activity-title"
              label="Activity title"
              value={title}
              placeholder="Activity title"
              size="large"
              onChange={(evt) => setTitle(evt.target.value)}
            />
          </Form.Item>

          <MarkdownEditor
            label="Description"
            content={content}
            placeholder="Write your question objective"
            onChange={setContent}
          />

          <hr />

          <Form.Item name="Correct answer" initialValue={activity?.correct_answer} rules={[{ required: true }]}>
            <Input
              type={'mathlive'}
              label="Correct answer"
              placeholder="Correct \ answer \ (e.g., \ f(x) = 2 + x)"
              value={answer}
              onChange={(evt) => setAnswer(evt.target.value)}
            />
          </Form.Item>

          <hr />

          <Collapse
            className="advanced-options-collapse"
            ghost
            expandIconPosition="end"
            items={[
              {
                key: '1',
                label: 'Show advanced options',
                children: (
                  <div className="advanced-options-container">
                    <MarkdownEditor label="Solution" content={solution} placeholder="Solution" onChange={setSolution} />
                    <MarkdownEditor label="Hints" content={hint} placeholder="Hints" onChange={setHint} />
                  </div>
                ),
              },
            ]}
          />
        </div>

        <div className="actions">
          <Button type="default" onClick={() => dispatch(setActivityWizardModalOpen(false))}>
            Cancel
          </Button>

          <Button type="primary" onClick={handleSaveActivity} loading={isCustomModulesLoading}>
            {isEditing ? 'Update' : 'Create'}
          </Button>
        </div>
      </Form>
    </Container>
  )
}

export default LatexValidatedActivityForm
