import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  > .header {
    display: flex;
    align-items: center;
    gap: 10px;

    .icon-container {
      height: 38px;
      width: 38px;
      background-color: ${(props) => props.theme.colors.geekblue4};
      color: ${(props) => props.theme.colors.gray1};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;

      &.rounded {
        height: 45px;
        width: 45px;
        background:
          radial-gradient(217.52% 153.59% at 122.4% 118.47%, #9196ff 47.5%, #ac8fff 73%, #9eb4fe 94.27%), #f0f2f5;
        box-shadow:
          0px 3px 6px -4px rgba(0, 0, 0, 0.12),
          0px 6px 16px 0px rgba(0, 0, 0, 0.08),
          0px 9px 28px 8px rgba(0, 0, 0, 0.05);
        border-radius: 50%;

        .activity-type-icon {
          height: 28px;
          width: 28px;
        }
      }
    }

    .title {
      margin: 0px;
    }
  }

  > .activity-form {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .activity-form-container {
      max-height: calc(100vh - 320px);
      overflow: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0px 32px;
      margin: 0px -32px;

      hr {
        width: 100%;
        border-top: 1px solid ${(props) => props.theme.colors.gray5};
      }

      .text {
        color: ${(props) => props.theme.colors.gray7};
        margin: 0px;

        &.bold {
          font-weight: 500;
        }

        &.small {
          font-size: 12px;
        }
      }

      .activity-type-selector {
        .ant-radio-button-wrapper {
          height: 50px;
          font-size: 14px;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .code-template-select,
      .user-input-language-select {
        width: 100%;
      }

      .code-snippet-cascader {
        width: 100%;
        margin: 0px;
      }

      .code-snippet-description {
        font-size: 12px;
        margin: -6px 0px 20px;
      }

      .option-box {
        background-color: ${(props) => props.theme.colors.geekblue1};
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 14px;
        padding: 16px 14px;

        > .content {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;

          .title-container {
            display: flex;
            align-items: center;
            gap: 5px;

            .title {
              color: ${(props) => props.theme.colors.gray13};
              font-size: 12px;
              margin: 0px;
            }

            .icon {
              height: 20px;
              width: 20px;
              color: ${(props) => props.theme.colors.geekblue4};
            }
          }

          .description {
            color: ${(props) => props.theme.colors.gray7};
            font-size: 12px;
            margin: 0px;
          }
        }

        > .extra-content {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }

      .options-container {
        display: flex;
        gap: 12px;

        .option-box {
          width: 100%;
        }
      }

      .answers-container {
        display: flex;
        flex-direction: column;

        .header {
          display: flex;
          justify-content: space-between;
          gap: 10px;
          margin-bottom: 12px;

          .title {
            color: ${(props) => props.theme.colors.gray7};
            margin: 0px;
          }

          .actions-header {
            display: flex;
            gap: 10px;

            ${(props) =>
              !props.$isEditing &&
              css`
                margin-right: 48px;
              `}

            .item {
              width: 50px;
              color: ${(props) => props.theme.colors.gray7};
              font-size: 12px;
              text-align: center;
              margin: 0px;
            }
          }
        }

        .answer-box {
          position: relative;
          display: flex;
          gap: 10px;
          padding: 6px 0px;

          &.is-dragging {
            opacity: 0.4;
          }

          .drag-icon-container {
            display: flex;
            align-items: center;
          }

          > .answer-container {
            width: 100%;

            .mdxeditor {
              display: grid;
            }
          }

          .actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            flex: 1;

            .icon-container {
              width: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-right: 1px solid ${(props) => props.theme.colors.gray4};
              margin: 0px;
              cursor: pointer;
              user-select: none;

              &:last-child {
                border: none;
                padding-right: 0px;
              }

              svg {
                color: ${(props) => props.theme.colors.red4};
                font-size: 24px;
                transition: all 0.3s;
              }

              .drag-button {
                background-color: transparent;
              }

              &:hover svg {
                color: ${(props) => props.theme.colors.red6};
              }
            }
          }
        }

        .add-button {
          background-color: ${(props) => props.theme.colors.gray1};
        }
      }

      .advanced-options-collapse {
        background-color: ${(props) => props.theme.colors.geekblue2};
        border-radius: 4px;

        .ant-collapse-item {
          .ant-collapse-header {
            padding: 20px;

            .ant-collapse-header-text {
              color: ${(props) => props.theme.colors.gray7};
            }

            .ant-collapse-expand-icon {
              svg {
                height: 18px;
                width: 18px;
              }
            }
          }

          .ant-collapse-content-box  {
            padding: 20px;
          }

          .advanced-options-container {
            display: flex;
            flex-direction: column;
            gap: 12px;
          }
        }
      }
    }

    > .actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
    }
  }
`
