import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Tag, Switch, Modal, Tooltip } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  Eye24Regular,
  Edit24Regular,
  Warning24Regular,
  Save24Regular,
  ArrowSquareDown24Regular,
  Power24Regular,
} from '@fluentui/react-icons'
import { showToast } from '@/utils/toast'
import usePreventTabClose from '@/hooks/usePreventTabClose'
import Button from '@/components/Button'
import { toggleEmailVerificationModal } from '@/store/users/actions'
import {
  updateCustomModuleVersion,
  createCustomModuleVersionEdit,
  createCustomModuleVersionStop,
  setCustomModuleContentJson,
  setCustomModuleMode,
  setPublishModalOpen,
} from '@/store/customModules/actions'
import { Container } from './styles'

const SecondaryNavbar = () => {
  const dispatch = useDispatch()

  const [isAutosaveEnabled, setIsAutosaveEnabled] = useState(
    JSON.parse(localStorage.getItem('dw-autosave-custom-module-enabled')) === true,
  )

  const { userProfile, emailValidationCodeIsValidated } = useSelector((state) => state.users)
  const {
    currentCustomModule,
    currentCustomModuleVersion,
    contentJson,
    isContentJsonDirty,
    mode,
    isCustomModulesLoading,
    isCustomModuleVersionsLoading,
  } = useSelector((state) => state.customModules)
  const { currentLab } = useSelector((state) => state.labs)

  const isEmailValidated = userProfile?.email_validated_at || emailValidationCodeIsValidated
  const requireEmailValidated = userProfile?.organization?.config?.launch_labs_requires_email_validated

  const isStorageFull = currentCustomModule?.total_size_in_mb >= currentCustomModule?.storage_limit

  const isDeviceStopping = currentLab?.allocated_session?.devices?.some((d) => d.status === 'stopping')

  const contentJsonRef = useRef(contentJson)

  usePreventTabClose(isContentJsonDirty && ['edit', 'amend'].includes(mode))

  const handleSetAutosaveEnabled = (value) => {
    localStorage.setItem('dw-autosave-custom-module-enabled', value)
    setIsAutosaveEnabled(value)
  }

  const handleStartEditing = () => {
    if (requireEmailValidated && !isEmailValidated) {
      dispatch(toggleEmailVerificationModal(true))
      return
    }

    if (currentCustomModuleVersion?.status === 'draft') {
      dispatch(
        createCustomModuleVersionEdit(
          currentCustomModuleVersion?.custom_module?.id,
          currentCustomModuleVersion?.id,
          () => dispatch(setCustomModuleMode('edit')),
          (message) => showToast(message, 'error'),
        ),
      )
    }

    if (currentCustomModuleVersion?.status === 'published') {
      dispatch(setCustomModuleMode('amend'))
    }
  }

  const handleStopEditing = () => {
    const stopEditing = () => {
      if (mode === 'edit') {
        dispatch(
          createCustomModuleVersionStop(
            currentCustomModuleVersion?.custom_module?.id,
            currentCustomModuleVersion?.id,
            {
              reason: 'manual',
            },
            () => {
              dispatch(setCustomModuleContentJson(currentCustomModuleVersion?.content_json))
              dispatch(setCustomModuleMode('browse'))
            },
            (message) => showToast(message, 'error'),
          ),
        )
        return
      }

      if (mode === 'amend') {
        dispatch(setCustomModuleContentJson(currentCustomModuleVersion?.content_json))
        dispatch(setCustomModuleMode('preview'))
      }
    }

    if (isContentJsonDirty) {
      Modal.confirm({
        title: 'You have unsaved changes',
        content: 'Are you sure you want to stop editing?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Yes, stop and lose changes',
        cancelText: 'No, keep editing',
        onOk: stopEditing,
        okButtonProps: {
          danger: true,
          type: 'primary',
        },
      })

      return
    }

    stopEditing()
  }

  const handleCustomModuleVersionUpdate = async (isQuiet, auxContentJson) => {
    const updatedCustomModuleVersion = {
      content_json: auxContentJson || contentJson,
    }

    await dispatch(
      updateCustomModuleVersion(
        currentCustomModuleVersion?.custom_module?.id,
        currentCustomModuleVersion?.id,
        updatedCustomModuleVersion,
        isQuiet ? () => {} : () => showToast('Content updated successfully!'),
        (err) => showToast(err, 'error'),
      ),
    )
  }

  const handlePublish = () => {
    dispatch(setPublishModalOpen(true))
  }

  const renderContent = () => {
    if (mode === 'browse')
      return (
        <>
          <Eye24Regular className="icon" /> You’re browsing
        </>
      )

    if (mode === 'preview')
      return (
        <>
          <Eye24Regular className="icon" /> You’re previewing
        </>
      )

    if (mode === 'edit')
      return (
        <>
          <Edit24Regular className="icon" /> You’re editing
        </>
      )

    if (mode === 'amend')
      return (
        <>
          <Warning24Regular className="icon" /> You’re in amend mode. Any changes you save will be instantly visible
        </>
      )
  }

  const renderActions = () => {
    if (mode === 'browse' && !currentCustomModuleVersion?.allocated_by) {
      return (
        <Tooltip title={isStorageFull ? 'Maximum Storage limit reached, contact support' : ''}>
          <Button
            className="button power-button off"
            type="primary"
            icon={<Power24Regular />}
            onClick={handleStartEditing}
            loading={isCustomModulesLoading || isDeviceStopping}
            disabled={isStorageFull}
          >
            Start editing
          </Button>
        </Tooltip>
      )
    }

    if (mode === 'edit')
      return (
        <>
          <div className="autosave-container">
            Autosave{' '}
            <Switch
              size="small"
              value={isAutosaveEnabled}
              onChange={handleSetAutosaveEnabled}
              loading={isCustomModulesLoading}
            />
          </div>

          <Tooltip title={isContentJsonDirty ? 'Save changes' : 'All changes are saved'}>
            <Button
              className="button save-button"
              icon={<Save24Regular className="icon" />}
              onClick={() => handleCustomModuleVersionUpdate()}
              loading={isCustomModulesLoading}
              disabled={!isContentJsonDirty}
            />
          </Tooltip>

          <Tooltip
            title={
              isContentJsonDirty
                ? 'Save changes before publishing'
                : isStorageFull
                  ? 'Maximum Storage limit reached, contact support'
                  : ''
            }
          >
            <Button
              className="button publish-button"
              type="secondary"
              icon={<ArrowSquareDown24Regular className="icon" />}
              onClick={handlePublish}
              loading={isCustomModulesLoading}
              disabled={isContentJsonDirty || isStorageFull}
            >
              Publish
            </Button>
          </Tooltip>

          <Button
            className="button power-button on"
            type="primary"
            icon={<Power24Regular />}
            onClick={handleStopEditing}
            loading={isCustomModulesLoading || isDeviceStopping}
          >
            Stop editing
          </Button>
        </>
      )

    if (mode === 'preview') {
      return (
        <Button
          className="button power-button off"
          type="primary"
          icon={<Power24Regular />}
          onClick={handleStartEditing}
        >
          Amend content
        </Button>
      )
    }

    if (mode === 'amend') {
      return (
        <>
          <Button
            className="button publish-button"
            type="secondary"
            onClick={() => handleCustomModuleVersionUpdate()}
            loading={isCustomModulesLoading}
          >
            <ArrowSquareDown24Regular className="icon" loading={isCustomModulesLoading} /> Save and Publish
          </Button>

          <Button
            className="button power-button on"
            type="primary"
            icon={<Power24Regular />}
            onClick={handleStopEditing}
            loading={isCustomModulesLoading || isDeviceStopping}
          >
            Stop editing
          </Button>
        </>
      )
    }
  }

  useEffect(() => {
    contentJsonRef.current = contentJson
  }, [contentJson])

  useEffect(() => {
    let autoSaveInterval
    const shouldAutoSave = isAutosaveEnabled && ['edit'].includes(mode)
    if (!shouldAutoSave) {
      if (autoSaveInterval) clearInterval(autoSaveInterval)
      return
    }

    autoSaveInterval = setInterval(() => {
      handleCustomModuleVersionUpdate(true, contentJsonRef.current)
    }, 30000)
    return () => clearInterval(autoSaveInterval)
  }, [mode, isAutosaveEnabled])

  if (!mode) return null

  return (
    <Container className="custom-module-secondary-navbar-container" $mode={mode}>
      {currentCustomModuleVersion && !isCustomModuleVersionsLoading && (
        <>
          <div className="text-container">
            {renderContent()}

            <Tag className={`status-tag ${currentCustomModuleVersion?.status}`}>
              <span className="indicator" /> {currentCustomModuleVersion?.status}
            </Tag>
          </div>

          <div className="actions">{renderActions()}</div>
        </>
      )}
    </Container>
  )
}

export default SecondaryNavbar
