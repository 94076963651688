import { SET_INVITES_LOADING, INVITE_UPDATED, RESET_INVITES_STATE, SET_INVITES_ERROR } from '@/store/invites'
import { getInvite, postInviteAccept } from '@/services/invites'

const fetchInvite = (inviteId) => async (dispatch, getState) => {
  try {
    dispatch(SET_INVITES_LOADING(true))

    const { userProfile } = getState().users

    const invite = await getInvite(inviteId)
    dispatch(INVITE_UPDATED(invite))

    const isUserInvite = invite?.invite_type === 'user_invite'
    const isInvitedUser = invite?.email === userProfile?.email

    if (isUserInvite && !isInvitedUser) {
      dispatch(SET_INVITES_ERROR(`Log in as ${invite?.email} to accept this invite`))
    }
  } catch (error) {
    const errorMessage = error?.message || error?.detail
    dispatch(SET_INVITES_ERROR(errorMessage))
  } finally {
    dispatch(SET_INVITES_LOADING(false))
  }
}

const acceptInvite = (inviteId) => async (dispatch) => {
  try {
    dispatch(SET_INVITES_LOADING(true))

    await postInviteAccept(inviteId)
    return true
  } catch (error) {
    const errorMessage = error?.message || error?.detail
    dispatch(SET_INVITES_ERROR(errorMessage))
  } finally {
    dispatch(SET_INVITES_LOADING(false))
  }
}

const resetCurrentInvite = () => (dispatch) => {
  dispatch(INVITE_UPDATED(null))
}

const resetInvitesState = () => (dispatch) => {
  dispatch(RESET_INVITES_STATE())
}

export { fetchInvite, acceptInvite, resetCurrentInvite, resetInvitesState }
