import {
  TextAddT24Regular,
  Code24Regular,
  MathFormatProfessional24Regular,
  DrawShape24Regular,
} from '@fluentui/react-icons'

const activityWidgetTypes = [
  { name: 'Text', code: 'text', icon: <TextAddT24Regular className="widget-icon" /> },
  { name: 'Code', code: 'code', icon: <Code24Regular className="widget-icon" /> },
  { name: 'Latex', code: 'latex', icon: <MathFormatProfessional24Regular className="widget-icon" /> },
  { name: 'Drawing', code: 'drawing', icon: <DrawShape24Regular className="widget-icon" />, comingSoon: true },
]

export default activityWidgetTypes
