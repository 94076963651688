import { Container, Select as SSelect } from './styles'

const Select = ({ className, label, fixedLabel, ...rest }) => {
  const value = rest?.value || rest?.defaultValue

  return (
    <Container $hasContent={value !== undefined && value !== ''} $fixedLabel={fixedLabel} $isDisabled={rest?.disabled}>
      {label && <p className="label">{label}</p>}

      <SSelect className={`dw-select ${className || ''}`} {...rest} />
    </Container>
  )
}

export default Select
