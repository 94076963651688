import styled from 'styled-components'
import { PrismBaseTheme } from '@/utils/prism/prismBaseTheme'
import { PrismTomorrowNightBlueTheme } from '@/utils/prism/prismTomorrowNightBlueTheme'

export const Container = styled.div`
  &.light-theme {
    ${PrismBaseTheme}
  }

  &.dark-theme {
    ${PrismTomorrowNightBlueTheme}
  }

  strong {
    font-weight: 600;
  }

  img {
    width: fit-content;
    max-width: 100%;
    margin-bottom: 1em;
  }

  ul,
  ol {
    li {
      color: ${(props) => props.theme.colors.gray10};
      line-height: 24px;
      padding-bottom: 4px;

      p {
        margin: 0px;
      }
    }
  }

  pre {
    code {
      font-family: menlo, consolas, 'DejaVu Sans Mono', monospace !important;
    }
  }

  h1,
  h2,
  h3 {
    color: ${(props) => props.theme.colors.gray10};
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin: 26px 0px 6px;
  }

  h4,
  h5,
  h6 {
    color: ${(props) => props.theme.colors.gray9};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 12px 0px 6px;
  }

  table {
    width: 100%;
    background-color: ${(props) => props.theme.colors.gray3};
    text-align: left;
    display: block;
    overflow: auto;
    border-spacing: 0px;
    border: 1px solid ${(props) => props.theme.colors.gray4};
    line-height: 16px;
    border-radius: 3px;
    margin: 20px auto;

    thead tr:first-child {
      background: ${(props) => props.theme.colors.gray9};
      border: none;

      th {
        color: ${(props) => props.theme.colors.gray1};
      }
    }

    thead,
    tr:nth-child(even) {
      color: ${(props) => props.theme.colors.gray10};
      background-color: ${(props) => props.theme.colors.gray3};
    }

    tr:nth-child(odd) {
      color: ${(props) => props.theme.colors.gray10};
      background-color: ${(props) => props.theme.colors.gray1};
    }

    th:first-child,
    td:first-child {
      padding-left: 20px;
    }

    th {
      min-width: 68px;
      font-weight: 500;
    }

    th,
    td {
      font-size: 12px;
      border: none;
      padding: 6px;
    }

    tbody tr:hover {
      background-color: ${(props) => props.theme.colors.gray4};
      cursor: default;
    }
  }

  .jp-Cell {
    &.jp-CodeCell {
      * {
        font-family: menlo, consolas, 'DejaVu Sans Mono', monospace;
      }

      .highlight,
      .jp-RenderedText[data-mime-type='application/vnd.jupyter.stderr'] {
        background-color: ${(props) => props.theme.colors.gray1};
      }
    }

    .jp-InputPrompt,
    .jp-OutputPrompt {
      display: none;
    }

    .jp-InputArea-editor,
    .jp-OutputArea-output {
      border: none;
      padding: 0px;

      .CodeMirror,
      .CodeMirror .highlight {
        background: ${(props) => props.theme.colors.gray1};
      }

      pre {
        color: ${(props) => props.theme.colors.gray10};
        border-radius: 2px;
        padding: 6px;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 600;
      margin: 18px 0px 12px;
    }

    h1 {
      font-size: 29px;
      line-height: 38px;
    }

    h2 {
      font-size: 24px;
      line-height: 32px;
    }

    h3 {
      font-size: 18px;
      line-height: 24px;
    }

    h4,
    h5,
    h6 {
      font-size: 16px;
      line-height: 20px;
    }

    .anchor-link {
      display: none;
    }

    img {
      width: fit-content;
      max-width: 100%;
      margin-bottom: 1em;
    }

    ul,
    ol {
      li {
        line-height: 24px;
        padding-bottom: 4px;

        p {
          margin: 0px;
        }
      }
    }

    table {
      width: 100%;
      background-color: ${(props) => props.theme.colors.gray3};
      text-align: left;
      display: block;
      overflow: auto;
      border-spacing: 0px;
      border: 1px solid ${(props) => props.theme.colors.gray4};
      line-height: 16px;
      border-radius: 3px;
      margin: 20px auto;

      thead tr:first-child {
        background: ${(props) => props.theme.colors.gray9};
        border: none;

        th {
          color: ${(props) => props.theme.colors.gray1};
        }
      }

      thead,
      tr:nth-child(even) {
        color: ${(props) => props.theme.colors.gray10};
        background-color: ${(props) => props.theme.colors.gray3};
      }

      tr:nth-child(odd) {
        color: ${(props) => props.theme.colors.gray10};
        background-color: ${(props) => props.theme.colors.gray1};
      }

      th:first-child,
      td:first-child {
        padding-left: 20px;
      }

      th {
        min-width: 68px;
        font-weight: 500;
      }

      th,
      td {
        font-size: 12px;
        border: none;
        padding: 6px;
      }

      tbody tr:hover {
        background-color: ${(props) => props.theme.colors.gray4};
        cursor: default;
      }
    }
  }

  &.dark-theme {
    .jp-InputArea-editor {
      .CodeMirror,
      .CodeMirror .highlight {
        background: ${(props) => props.theme.colors.geekblue2};

        pre {
          color: ${(props) => props.theme.colors.gray10};
          background: ${(props) => props.theme.colors.geekblue1};
        }
      }
    }
  }
`
