import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { isDataWarsHostName } from '@/helpers/env'
import useQueryParam from '@/hooks/useQueryParam'
import usePageTracking from '@/hooks/usePageTracking'
import { toggleUpgradeToProSuccessModal } from '@/store/app/actions'

const RouteTracking = ({ children, ...props }) => {
  const dispatch = useDispatch()

  const [proCheckoutSuccessful] = useQueryParam('pro_successful')

  if (isDataWarsHostName) {
    usePageTracking()
  }

  useEffect(() => {
    if (proCheckoutSuccessful === 'true') {
      dispatch(toggleUpgradeToProSuccessModal(true))
    }
  }, [proCheckoutSuccessful]) // eslint-disable-line react-hooks/exhaustive-deps

  return children || <Outlet />
}

export default RouteTracking
