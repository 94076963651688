import styled from 'styled-components'
import { Modal as AntForm } from 'antd'

export const Modal = styled(AntForm)`
  max-width: 720px;
  width: 100% !important;

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 0px;

    > .content {
      width: 530px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 18px;

      .title {
        width: 100%;
        display: flex;
        gap: 10px;
        font-weight: 400;
        margin: 0px;

        .icon {
          height: 30px;
          width: 30px;
          color: ${(props) => props.theme.colors.gold5};
        }
      }

      .warning-block {
        width: calc(100% - 64px);
        background-color: ${(props) => props.theme.colors.gold2};
        border: 1px solid ${(props) => props.theme.colors.gold5};
        border-radius: 5px;
        padding: 16px 32px;

        h5 {
          color: ${(props) => props.theme.colors.gray7};
          font-weight: 400;
          margin: 0px;
        }

        ul {
          margin: 0px;

          li {
            color: ${(props) => props.theme.colors.gray7};
            font-size: 16px;
          }
        }
      }

      .subtitle {
        width: 100%;
        color: ${(props) => props.theme.colors.gray7};
        font-weight: 400;
        line-height: 24px;
        margin: 0px;

        &.bold {
          color: ${(props) => props.theme.colors.gray10};
          font-weight: 700;
        }
      }

      .actions {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .text {
        margin: 0px;
      }
    }
  }
`
