import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'
import {
  ArrowCircleUpRight24Regular,
  Warning24Regular,
  Add24Regular,
  HatGraduation24Regular,
} from '@fluentui/react-icons'
import { capitalize } from '@/utils/functions'
import { showToast } from '@/utils/toast'
import { renderModuleImage } from '@/helpers/renderModuleImage'
import Button from '@/components/Button'
import { toggleEmailVerificationModal } from '@/store/users/actions'
import {
  createCustomModuleVersion,
  createCustomModuleVersionEdit,
  setCustomModuleMode,
} from '@/store/customModules/actions'
import ProfilePicture from '@/components/ProfilePicture'
import { Container } from './styles'

const CustomModuleInfoCard = () => {
  const dispatch = useDispatch()

  const { userProfile, emailValidationCodeIsValidated } = useSelector((state) => state.users)
  const { currentCustomModule, currentCustomModuleVersion, mode, isCustomModulesLoading } = useSelector(
    (state) => state.customModules,
  )
  const { currentLab } = useSelector((state) => state.labs)

  const isEmailValidated = userProfile?.email_validated_at || emailValidationCodeIsValidated
  const requireEmailValidated = userProfile?.organization?.config?.launch_labs_requires_email_validated

  const versions = currentCustomModule?.versions
  const isLastVersion = versions?.[versions?.length - 1]?.id === currentCustomModuleVersion?.id

  const isStorageFull = currentCustomModule?.total_size_in_mb >= currentCustomModule?.storage_limit

  const otherIsEditing =
    currentCustomModuleVersion?.allocated_by && currentCustomModuleVersion?.allocated_by?.id !== userProfile?.id

  const isDeviceStopping = currentLab?.allocated_session?.devices?.some((d) => d.status === 'stopping')

  const handleStartEditing = () => {
    if (requireEmailValidated && !isEmailValidated) {
      dispatch(toggleEmailVerificationModal(true))
      return
    }

    dispatch(
      createCustomModuleVersionEdit(
        currentCustomModuleVersion?.custom_module?.id,
        currentCustomModuleVersion?.id,
        () => dispatch(setCustomModuleMode('edit')),
        (message) => showToast(message, 'error'),
      ),
    )
  }

  const handleCreateNewVersion = () => {
    dispatch(
      createCustomModuleVersion(
        currentCustomModuleVersion?.custom_module?.id,
        (newCustomModuleVersion) => showToast('New version created'),
        (message) => showToast(message, 'error'),
      ),
    )
  }

  return (
    <Container className="custom-module-info-card">
      {otherIsEditing && (
        <div className="notification">
          <Warning24Regular className="icon" />
          Project is currently being edited by {currentCustomModuleVersion?.allocated_by?.name}
        </div>
      )}

      <div className="module-card">
        <div className="row">
          <div className="title-container">
            <h3 className="title">{currentCustomModuleVersion?.custom_module?.name}</h3>

            {currentCustomModuleVersion?.custom_module?.description_html && (
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: currentCustomModuleVersion?.custom_module?.description_html,
                }}
              />
            )}
          </div>

          <div className="image-container">{renderModuleImage(currentCustomModuleVersion?.custom_module)}</div>
        </div>

        <div className="row">
          <span className="author-info">
            <ProfilePicture
              src={currentCustomModuleVersion?.author?.avatar_url}
              alt={capitalize(currentCustomModuleVersion?.author?.name || 'Anonymous')}
              size="small"
              hideBadge
              disableTooltip
            />

            {currentCustomModuleVersion?.author?.name
              ? `${capitalize(currentCustomModuleVersion?.author?.name)}`
              : 'Anonymous'}
          </span>

          <div className="actions">
            {mode === 'browse' && (
              <Tooltip title={isStorageFull ? 'Maximum Storage limit reached, contact support' : ''}>
                <Button
                  className="main-button"
                  type="primary"
                  size="large"
                  onClick={handleStartEditing}
                  loading={isCustomModulesLoading || isDeviceStopping}
                  disabled={currentCustomModuleVersion?.allocated_by || isStorageFull}
                >
                  Start editing <ArrowCircleUpRight24Regular className="icon" />
                </Button>
              </Tooltip>
            )}

            {['preview', 'amend'].includes(mode) && (
              <>
                <Tooltip
                  title={
                    !isLastVersion
                      ? 'You can only create a new version if you are viewing the latest version'
                      : mode === 'amend'
                        ? "You can't create a new version while amending"
                        : isStorageFull
                          ? 'Maximum Storage limit reached, contact support'
                          : ''
                  }
                >
                  <Button
                    className="main-button"
                    type="primary"
                    size="large"
                    onClick={handleCreateNewVersion}
                    disabled={mode === 'amend' || !isLastVersion || isStorageFull}
                    loading={isCustomModulesLoading}
                  >
                    <Add24Regular className="icon" /> Create new version
                  </Button>
                </Tooltip>

                <Link to={`/project/${currentCustomModuleVersion?.id}`} target="_blank">
                  <Button className="main-button" type="primary" size="large" disabled={mode === 'amend'}>
                    <HatGraduation24Regular className="icon" /> Preview as student
                  </Button>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </Container>
  )
}

export default CustomModuleInfoCard
